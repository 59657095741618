import {GetterTree} from 'vuex';
import {RootState} from '@/store';
import {State} from './state';

export type Getters = {
  getIsErrorAuthentication(state: State): boolean
  getLoggedUser(state: State): { [key: string]: any } | null
  userChangesInformation(state: State): boolean
  getUserRole(state: State): string,
  getAllPermissions(state: State): any
}

export const getters: GetterTree<State, RootState> & Getters = {
  getIsErrorAuthentication: (state) => state.isError,
  getLoggedUser: (state) => state.loggedUser,
  userChangesInformation: (state) => !!(state.loggedUser && (state.loggedUser.role === 'ADMIN' || state.loggedUser.role === 'DEVELOPMENT_MANAGER')),
  getUserRole: (state) => state.loggedUser && state.loggedUser.role,
  getAllPermissions: (state) => state.permissions
};
