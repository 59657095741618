import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import {roles} from "@/services/roles/serviceRoles";
import {store} from "@/store";
import {AuthenticationMutationTypes} from "@/store/modules/authentication/mutation-types";
import {Roles} from "@/components/types/enums";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'layout',
    component: () => import('@/components/layout/BaseLayout.vue'),
    children: [
      {
        path: 'search',
        name: 'search',
        // component: () => import('@/components/SearchPage.vue'),
        component: () => import('@/components/search-layouts/PageSearchWrapper.vue'),
      },
      {
        path: 'articles',
        name: 'articles',
        component: () => import('@/components/articles/ArticlesTable.vue'),
      },
      {
        path: 'article/:id',
        name: 'article',
        component: () => import('@/components/article-card/CardWrapper.vue'),
      },
      {
        path: 'sheets',
        name: 'development-sheets',
        component: () => import('@/components/development-sheet/DevelopmentSheets.vue'),
      },
      {
        path: 'sheet/:id',
        name: 'articles-sheet',
        component: () => import('@/components/development-sheet/ArticlesDevelopmentSheet.vue'),
      },
      {
        path: 'sheet/new',
        name: 'create-sheet',
        component: () => import('@/components/development-sheet/SheetWrapper.vue'),
      },
      {
        path: 'sheet/new/:id',
        name: 'update-sheet',
        component: () => import('@/components/development-sheet/SheetWrapper.vue'),
      },
      {
        path: 'prices',
        name: 'price_search',
        component: () => import('@/components/price/PriceSearchPage.vue'),
      },
      {
        path: 'price/:id',
        name: 'price',
        component: () => import('@/components/price/PricePage.vue'),
      },
      {
        path: 'gbs',
        name: 'gbs',
        component: () => import('@/components/price/GbsPriceViews/GbsPricePage.vue'),
      },
      {
        path: 'rrc',
        name: 'rrc',
        component: () => import('@/components/price/RrcPricePage.vue'),
      },
      {
        path: 'price/article/:id',
        name: 'price_article',
        component: () => import('@/components/price/PricePage.vue'),
      },
      {
        path: 'price/sheet/:id',
        name: 'price_sheet',
        component: () => import('@/components/price/PricePage.vue'),
      },
      {
        path: 'price/list/:ids',
        name: 'price_list',
        component: () => import('@/components/price/PricePage.vue'),
      },
      // {
      //   path: 'search-articles-by-vin',
      //   name: 'search-articles-by-vin',
      //   component: () => import('@/components/vin/SearchVinPage.vue')
      // },
      // {
      //   path: 'search-vehicles-by-vin',
      //   name: 'search-vehicles-by-vin',
      //   component: () => import('@/components/vin/ViewAdvancedInfoByVin/ViewAdvancedInfoByVinPage.vue')
      // },
      {
        path: 'prices-list',
        name: 'prices_list',
        component: () => import('@/components/prices-list/PricesList.vue'),
      },
      {
        path: 'cars',
        name: 'cars_search',
        component: () => import('@/components/CarsSearchPage.vue'),
      },
      {
        path: 'cars/brands',
        name: 'cars_brands',
        component: () => import('@/components/brands/BrandsTable.vue'),
      },
      {
        path: 'cars/brands/:id/models',
        name: 'cars_models',
        component: () => import('@/components/models/ModelsTable.vue'),
      },
      {
        path: 'vehicles',
        name: 'vehicles',
        component: () => import('@/components/vehicles/VehiclesTable.vue'),
      },
      {
        path: 'vehicle/:carId',
        name: 'vehicle',
        component: () => import('@/components/vehicle-card/VehicleCardWrapper.vue'),
      },
      {
        path: 'brands',
        name: 'brands',
        component: () => import('@/components/brands/BrandsTable.vue'),
      },
      {
        path: 'brands/:id/models',
        name: 'brand-models',
        component: () => import('@/components/models/ModelsTable.vue'),
      },
      {
        path: 'cross-brands',
        name: 'cross-brands',
        component: () => import('@/components/cross-brands/WrapperCrossBrands.vue'),
      },
      {
        path: '/commodity-groups',
        name: 'commodity-groups',
        component: () => import('@/components/commodity-groups/CommodityGroupsAndParamsWrapper.vue')
      },
      {
        path: '/commodity-groups/:id',
        name: 'commodity-groups-param',
        component: () => import('@/components/commodity-groups/CommodityGroupsParam.vue')
      },
      {
        path: 'export-crosses',
        name: 'cross-export',
        component: () => import('@/components/audit/ArticleExportWrapper.vue'),
      },
      {
        path: '/reports',
        name: 'reports',
        component: () => import('@/components/reports/ReportsMainView.vue')
      },
      {
        path: '/upload',
        name: 'upload',
        component: () => import('@/components/upload/Upload.vue')
      },
      {
        path: '/admin',
        name: 'admin-panel',
        component: () => import('@/components/admin-panel/AdminPanelWrapper.vue')
      },
      {
        path: '/product-groups-certificates',
        name: 'request-certificates',
        component: () => import('@/components/certificates/product-groups/ProductGroupsCertificates.vue')
      },
      {
        path: '/request-certificates',
        name: 'request-certificates',
        component: () => import('@/components/certificates/CertificatesRequest.vue')
      },
      {
        path: '/current-certificates',
        name: 'current-certificates',
        component: () => import('@/components/certificates/CurrentCertificates.vue'),
      },
      {
        path: '/certificates-product-groups',
        name: 'certificates-product-groups',
        component: () => import('@/components/certificates/product-groups/ProductGroupsCertificates.vue'),
      },
      {
        path: '/certificate-layouts-requests',
        name: 'certificate-layouts-requests',
        component: () => import('@/components/certificates/CertificateLayoutsRequests.vue'),
      },
      {
        path: '',
        redirect: 'search',
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/LoginPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/components/LoginPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

if(!store.getters.getUserRole) {
  let loggedUser = localStorage.getItem('loggedUser');
  let cid = localStorage.getItem('cid');
  if (loggedUser && cid) store.commit(AuthenticationMutationTypes.SET_USER, JSON.parse(loggedUser));
}

router.beforeEach(async (to, from, next) => {

  if (to.fullPath.includes('/sheets')) {
    if(store.getters.getAllPermissions.search_mastering_sheet[store.getters.getUserRole]) {
      next()
    } else {
      next({path: '/search'})
    }
  }

  else if (to.fullPath.includes('/prices')) {
    if(store.getters.getAllPermissions.create_prices_list[store.getters.getUserRole]) {
      next()
    } else {
      next({path: '/search'})
    }
  }

  else if (to.fullPath.includes('/brands') && !to.fullPath.includes('vehicleType')) {
    if(store.getters.getAllPermissions.search_vehicles[store.getters.getUserRole]) {
      next()
    } else {
      next({path: '/search'})
    }
  }

  else if (to.fullPath.includes('vehicleState')) {
    if(store.getters.getAllPermissions.blocked_vehicles[store.getters.getUserRole]) {
      next()
    } else {
      next({path: '/search'})
    }
  }

  else if (to.fullPath.includes('/admin')) {
    if(store.getters.getAllPermissions.edit_user[store.getters.getUserRole]) {
      next()
    } else {
      next({path: '/search'})
    }
  }

  else if (to.fullPath.includes('/articles')) {
    await store.dispatch('GET_PERMISSIONS_BY_ROLE', Roles[store.getters.getLoggedUser.role])

    if (store.getters.getAllPermissions.search_article[store.getters.getUserRole]) {
      next()
    } else {
      next({path: '/search'})
    }
  }

  else if (to.fullPath.includes('export-crosses')) {
    await store.dispatch('GET_PERMISSIONS_BY_ROLE', Roles[store.getters.getLoggedUser.role])

    if (store.getters.getAllPermissions.export_crosses[store.getters.getUserRole]) {
      next()
    } else {
      next({path: '/search'})
    }
  }

  else {
    next()
  }

});

export default router;
