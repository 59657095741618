import {httpClient} from "@/httpClient";
import { setQueryClearCache } from "@/utils/url";
import axios from "axios";

function getFileByArticle(type: string, article: string) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/${type}/${article}`).then(response => {
    return response.data.map((image: any) => {
      const imageUrl = `${process.env.VUE_APP_URL_FILES}${image.split('\\').join('/')}`

      return setQueryClearCache(imageUrl)
    })
  })
}

function addFileArticle(type: string, payload: object) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/${type}`, payload, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
    const imageUrl = `${process.env.VUE_APP_URL_FILES}${response.data.split('\\').join('/')}`

    return setQueryClearCache(imageUrl)
  })
}

function deleteFileArticle(type: string, articleId: string, imageName: string) {
  let name = type === 'images' ? `imageName=${encodeURIComponent(imageName)}` : `drawName=${imageName}`;
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/${type}?articleId=${articleId}&${name}`).then(response => response.data)
}

function getImageArchive() {
  // Возвращаем ссылку для скачивания архива
  return `${axios.defaults.baseURL}/article/${localStorage.getItem('cid')}/archive?type=IMAGE`
  // return httpClient.get(`article/${localStorage.getItem('cid')}/archive?type=IMAGE`)
}

function getDrawArchive() {
  // Возвращаем ссылку для скачивания архива
  return `${axios.defaults.baseURL}/article/${localStorage.getItem('cid')}/archive?type=DRAW`
  // return httpClient.get(`article/${localStorage.getItem('cid')}/archive?type=IMAGE`)
}

export default {
  getFileByArticle,
  addFileArticle,
  deleteFileArticle,
  getImageArchive,
  getDrawArchive
};
