import {httpClient} from "@/httpClient";

// Карточка артикула (получение применяемости)
function getApplicability(articleId: string) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/cross/applic/${articleId}`).then(response => response.data)
}

// Карточка артикула (Применяемость - добавление в список автомобилей артикула)
function approveVehicles(id: string, payload: object) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/cross/applic/${id}`, payload).then(response => response.data)
}

// Карточка артикула (Применяемость - добавление в список автомобилей артикула)
function postApplicabLocalVehicle(id: string, payload: object) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/cross/applic/local/${id}`, payload).then(response => response.data)
}

// Удаление применяемости
function deleteVehicle(id: string) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/cross/applic/delete?id=${id}`).then(response => response.data)
}

// Удаление всей применяемости
function deleteAllVehicles(articleId: string) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/cross/applic/delete?articleId=${articleId}`).then(response => response.data)
}

function setCompleteness(crossId: number, payload: any) {
  return httpClient.patch(`/article/${localStorage.getItem('cid')}/cross/${crossId}/set-completeness`, payload).then((response) => response.data)
}

export default {
  getApplicability,
  approveVehicles,
  postApplicabLocalVehicle,
  deleteVehicle,
  deleteAllVehicles,
  setCompleteness
}
