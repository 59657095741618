export enum devices {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE_SIZE = 'mobile-size',
  LAPTOP_SIZE = 'laptop-size',
  DESKTOP_SIZE = 'desktop-size',
  TABLET_SIZE = 'tablet-size',
}

export const getDevice = (width: number) => {
  if (width < 426) return devices.MOBILE;
  if (width < 768 && width > 426) return devices.TABLET;
  return devices.DESKTOP;
}

export const getDeviceSize = (width: number) => {
  if (width < 768) return devices.MOBILE_SIZE;
  if (width < 1440) return devices.TABLET_SIZE;
  if (width < 1920) return devices.LAPTOP_SIZE;
  return devices.DESKTOP_SIZE;
}

export const getCurrentWidth = () => {
  return window.innerWidth;
}

export const getCurrentDevice = () => {
  return getDevice(getCurrentWidth());
}

export const getCurrentDeviceSize = () => {
  return getDeviceSize(getCurrentWidth());
}
