import {ActionTree, ActionContext} from 'vuex';
import {RootState} from '@/store';
import {State} from './state';
import {Mutations} from './mutations';
import {CardArticleActionTypes} from './action-types';
import articles from "@/services/articles";
import crossApplicab from "@/services/crossApplicab";
import cross from "@/services/cross";
import params from "@/services/params";
import imageService from '@/services/images';
import { CardArticleMutationTypes } from '@/store/modules/article-card/mutation-types';
import { IDataViewArticleCard } from '@/models/VehicleModel';
import { unsetQueryClearCache } from '../../../utils/url';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

type AddImageType = {
  files: any,
  articleId: string,
  view: IDataViewArticleCard
}

export interface Actions {
  [CardArticleActionTypes.SET_PARAMETER_VALUE]({commit}: AugmentedActionContext, {tab, articleId}: any): Promise<any>
  [CardArticleActionTypes.SET_TCDC]({commit}: AugmentedActionContext, payload: string): Promise<any>
  [CardArticleActionTypes.FETCH_IMAGES]({commit}: AugmentedActionContext, articleName: string): Promise<any>
  [CardArticleActionTypes.DELETE_IMAGE]({commit}: AugmentedActionContext, data: {key: string, articleId: string, file: string}): Promise<any>
  [CardArticleActionTypes.ADD_IMAGE]({commit}: AugmentedActionContext, data: any): Promise<any>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [CardArticleActionTypes.SET_PARAMETER_VALUE]({commit}, {tab, articleId}: any) {
    return new Promise((resolve, reject) => {
      if (tab === 'APPLICABILITY') {
        crossApplicab.getApplicability(articleId).then(response => {
          resolve(response)
        }).catch(() => {
          reject()
        })
      } else {
        // если мы забираем кроссы, то делаем доп запрос на hard
        if (tab.toLowerCase() === 'cross' && articleId) {
          cross.getHardCrosses(tab.toLowerCase(), articleId, '').then(response => {
            // console.log('Получили Hard', response.length, response.map((el: any)=> el.supplier))
            params.getParameterByKey(tab.toLowerCase(), articleId, '').then(response2 => {
              // console.log('Получили все Cross', response2.length)
              resolve(response.concat(response2.filter((el: any) => (el.direction === 'OE' || el.supplier === 'ABCP' || el.supplier === 'LAXIMO' || el.direction === 'ANALOG') && !response.some((article: any) => article.id === el.id))))
            }).catch(() => {
              reject()
            })
          }).catch(() => {
            reject()
          })
        } else if (articleId) {
          params.getParameterByKey(tab.toLowerCase(), articleId, '').then(response => {
            if (tab === 'PARAMS') {
              const sortParamByQueue = response.sort((nextParam: any, prevParam: any) => {
                return nextParam.param.queueNumber - prevParam.param.queueNumber;
              })

              resolve(sortParamByQueue)
            }

            resolve(response)
          }).catch(() => {
            reject()
          })
        }

      }
    })
  },
  async [CardArticleActionTypes.SET_TCDC]({commit}, payload) {
    return new Promise((resolve, reject) => {
      articles.getTCDC(payload).then(response => {
        resolve(response)
      }).catch(() => {
        reject()
      })
    })
  },
  async [CardArticleActionTypes.FETCH_IMAGES]({commit}, articleName) {
   const images = await imageService.getFileByArticle('images', articleName);
   const draw = await imageService.getFileByArticle('draw', articleName);

   commit(CardArticleMutationTypes.SET_DATA_SLIDER, [
     { name: 'Фото', value: [...images], key: 'IMAGES' },
     { name: 'Чертежи', value: [...draw], key: 'DRAW' },
   ])
  },
  async [CardArticleActionTypes.DELETE_IMAGE]({commit}, data: {key: string, articleId: string, file: string}) {
    const { key, articleId, file } = data
    const name = unsetQueryClearCache(file.substring(file.lastIndexOf('/') + 1))
    
    try {
      await imageService.deleteFileArticle(key.toLowerCase(), articleId, name);
      commit(CardArticleMutationTypes.UPDATE_SLIDER_IMAGES, { key, articleId, file })
    } catch (error) {
      console.error(error)
    }
  },
  async [CardArticleActionTypes.ADD_IMAGE]({commit}, { files, articleId, view }: AddImageType) {
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('articleId', articleId);
      formData.append('extension', files[i].type.split('/').pop());
      formData.append('file', files[i]);
    }

    try {
      await imageService.addFileArticle(view.key.toLowerCase(), formData).then((value) => {
        commit(CardArticleMutationTypes.ADD_IMAGE_SLIDER, {
          value,
          key: view.key
        })
      })
    } catch (error) {
      console.log(error);
    }
  }
};
