import {httpClient} from "@/httpClient";

function getAllUsers() {
  return httpClient.get('/admin/users').then((response) => response.data)
}

function createUser(payload: any) {
  payload.cid = localStorage.getItem('cid')
  payload.status = 'UNBLOCKED'
  return httpClient.post('/admin/users', payload)
}

function updateUser(payload: any) {
  payload.cid = localStorage.getItem('cid')
  return httpClient.put(`/admin/users/${payload.id}`, payload)
}

function blockedAndUnblockedUser(status: string, ids: Array<number | string>) {
  return httpClient.patch(`/admin/users?status=${status}`, ids)
}

function getUserById(id: string | number) {
  return httpClient.get(`/admin/users/${id}`).then((response: any) => response.data)
}

function tokenInfo() {
  return httpClient.get('/users/tokenInfo').then((response: any) => response.data)
}

export default {
  getAllUsers,
  createUser,
  updateUser,
  blockedAndUnblockedUser,
  getUserById,
  tokenInfo
}
