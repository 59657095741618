import { httpClient } from '@/httpClient';
import { IPageableContent } from '@/models/PageableModel';
import {
  ApproveRequests,
  Certificate,
  CertificateProductGroup,
  CertificateRequestsStatus,
  CertificateTypes,
  DocumentRequests,
  LayoutRequests,
  Request,
  RequestPayload
} from '@/models/CertificateModel';
import axios from 'axios';

function searchCertificatesByPrefix(prefix: any) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificates?prefix=${prefix}`).then((response) => response.data)
}

function fetchListCertificates(pageNumber = 0, pageSize = 20, type: keyof typeof CertificateTypes = CertificateTypes.CERTIFICATE): Promise<IPageableContent<Certificate>> {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificate/catalog/list?page=${pageNumber}&size=${pageSize}&type=${type}`).then((response) => response.data)
}

function fetchListDeprecatedCertificates() {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificate/catalog/list/deprecated`).then((response) => response.data)
}

function addCertificateFile(formData: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/certificate/catalog`, formData).then((response) => response.data)
}

function deleteCertificate(certificateId: number) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/certificate/catalog/${certificateId}`)
}

function getArticleCertificates(articleName: string): Promise<Array<Certificate>> {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificate/catalog?article=${articleName}`).then((response) => response.data)
}

function checkCertificate(articleName: string) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificates/check?article=${articleName}`).then((response) => response.data)
}

function certificateTemplate() {
  return `${axios.defaults.baseURL}/article/${localStorage.getItem('cid')}/certificates/excel/template`
}

function certificatesUpload(formData: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/certificates/excel/upload`, formData).then((response) => response.data)
}

function addRequest(payloadRequest: Array<RequestPayload>): Promise<Array<Request>> {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/certificates/request`, payloadRequest).then((response) => response.data)
}

function searchCertificateProductGroups(prefix: string) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificate/product/groups/search?name=${prefix}`).then((response) => response.data)
}

function fetchCertificationBrands() {
  return httpClient.get('/brand/isCert').then((response) => response.data)
}

function fetchRequests(pageNumber = 0, pageSize = 20, status: CertificateRequestsStatus, type: keyof typeof CertificateTypes = CertificateTypes.CERTIFICATE): Promise<IPageableContent<Request>> {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificates/requested?page=${pageNumber}&type=${type}&size=${pageSize}${status ? `&status=${status}` : '' }`).then((response) => response.data)
}

function documentRequest(payload: DocumentRequests) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/certificates/requested/document`, payload, {
    responseType: 'blob'
  }).then((response) => response.data)
}

function documentRequestApprove(payload: ApproveRequests) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/certificates/requested/document/approve`, payload, {
    responseType: 'blob'
  }).then((response) => response.data)
}

function requestsUpload(formData: FormData) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/certificates/request/excel`, formData).then((response) => response.data)
}

function requestsUploadTemplate() {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificates/request/excel/template`, {
    responseType: 'blob'
  }).then((response) => response.data)
}

function declarationReport(payload: { fileId: number }) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/declarations/excel/report`, payload, {
    responseType: 'blob'
  })
}

function declarationReportArticles(type: keyof typeof CertificateTypes) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/declarations/excel/report/articles?type=${type}`, '', {
    responseType: 'blob'
  })
}

function refusalReportArticles(type: keyof typeof CertificateTypes) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/refusal/excel/report/articles?type=${type}`, '', {
    responseType: 'blob'
  })
}

function uploadDeclarationProductGroup(formData: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/declarations/group/excel/upload`, formData).then((response) => response.data)
}

function uploadRefusalProductGroup(formData: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/refusal/excel/upload`, formData).then((response) => response.data)
}

function getAllDeclarationByType(pageNumber = 0, pageSize = 20, certificateType = CertificateTypes.REFUSAL) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/declarations/group?type=${certificateType}&page=${pageNumber}&size=${pageSize}`).then((response) => response.data)
}

function fetchCertificateProductGroups(pageNumber = 0, pageSize = 20): Promise<IPageableContent<CertificateProductGroup>> {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificate/product/groups?page=${pageNumber}&size=${pageSize}`).then((response) => response.data)
}

function addCertificateProductGroup(productGroupPayload: any): any {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/certificate/product/groups`, productGroupPayload).then((response) => response.data)
}

function deleteCertificateProductGroup(id: number): any {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/certificate/product/groups/${id}`)
}

function updateCertificateProductGroup(productGroupPayload: number): any {
  return httpClient.put(`/article/${localStorage.getItem('cid')}/certificate/product/groups`, productGroupPayload).then((response) => response.data)
}

function certificateReport(payload: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/certificates/excel/report?type=${payload.type}`, payload.subgroups, {
    responseType: 'blob'
  }).then((response) => response.data)
}

function certificatesLayoutsRequests(pageNumber = 0, pageSize = 20): Promise<IPageableContent<LayoutRequests>> {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificates/layout?page=${pageNumber}&size=${pageSize}`).then((response) => response.data)
}

function addCertificateProductSubGroup(payload: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/certificate/product/subgroups`, payload).then((response) => response.data)
}

function deleteCertificateProductSubGroup(id: number): any {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/certificate/product/subgroups/${id}`)
}

function updateCertificateProductSubGroup(productSubGroupPayload: any): any {
  return httpClient.put(`/article/${localStorage.getItem('cid')}/certificate/product/subgroups`, productSubGroupPayload).then((response) => response.data)
}

function listProductSubGroups(): any {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificate/product/subgroups?page=1&pageSize=1000`).then((response) => response.data.content)
}

function deleteLayoutRequests(layoutId: string): any {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/certificates/layout/${layoutId}`)
}

function downloadLayoutRequests(layoutId: string): any {
  return `${axios.defaults.baseURL}/article/${localStorage.getItem('cid')}/certificates/layout?requestId=${layoutId}`
}

function searchProductGroupsByCertificates() {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificate/product/groups/inCertificates`).then((response) => response.data)
}

function searchProductGroupsByRequests() {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/certificate/product/groups/inRequests`).then((response) => response.data)
}

function uploadArticlesToDeclarationProductGroup(formData: FormData) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/declarations/excel/upload`, formData).then((response) => response.data)
}

function declarationExcelTemplate() {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/declarations/excel/template`, {
    responseType: 'blob'
  }).then((response) => response.data)
}

function searchDeclarationsByType(value: string, type: keyof typeof CertificateTypes) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/declarations/group/search/${type}?name=${value}`).then(response => response.data)
}

function getRefusalProductGroups(pageNumber = 0, pageSize = 20) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/refusal?page=${pageNumber}&size=${pageSize}`).then(response => response.data)
}

function getDeclarationsProductGroups(pageNumber = 0, pageSize = 20) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/declarations?page=${pageNumber}&size=${pageSize}`).then(response => response.data)
}

function searchRefusalProductGroups(prefix: string) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/refusal/search?name=${prefix}`).then((response) => response.data)
}

function searchDeclarationProductGroups(prefix: string) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/declarations/search?name=${prefix}`).then((response) => response.data)
}

function uploadArticlesToRefusalProductGroup(formData: FormData) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/refusal/excel/upload/articles`, formData).then((response) => response.data)
}

function refusalExcelTemplateArticles() {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/refusal/excel/template/articles`, {
    responseType: 'blob'
  }).then((response) => response.data)
}

function refusalExcelTemplateGroup() {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/refusal/excel/template/groups`, {
    responseType: 'blob'
  }).then((response) => response.data)
}


export default {
  refusalReportArticles,
  refusalExcelTemplateGroup,
  refusalExcelTemplateArticles,
  uploadArticlesToRefusalProductGroup,
  searchDeclarationProductGroups,
  searchRefusalProductGroups,
  getDeclarationsProductGroups,
  getRefusalProductGroups,
  searchDeclarationsByType,
  declarationExcelTemplate,
  uploadArticlesToDeclarationProductGroup,
  searchProductGroupsByCertificates,
  searchProductGroupsByRequests,
  downloadLayoutRequests,
  deleteLayoutRequests,
  certificatesLayoutsRequests,
  listProductSubGroups,
  updateCertificateProductSubGroup,
  deleteCertificateProductSubGroup,
  addCertificateProductSubGroup,
  certificateReport,
  updateCertificateProductGroup,
  deleteCertificateProductGroup,
  addCertificateProductGroup,
  fetchCertificateProductGroups,
  requestsUploadTemplate,
  requestsUpload,
  documentRequest,
  fetchRequests,
  fetchCertificationBrands,
  searchCertificateProductGroups,
  addRequest,
  certificatesUpload,
  certificateTemplate,
  checkCertificate,
  getArticleCertificates,
  searchCertificatesByPrefix,
  fetchListCertificates,
  fetchListDeprecatedCertificates,
  addCertificateFile,
  deleteCertificate,
  documentRequestApprove,
  uploadDeclarationProductGroup,
  uploadRefusalProductGroup,
  getAllDeclarationByType,
  declarationReport,
  declarationReportArticles,
}
