import {MutationTree} from 'vuex';
import {State} from './state';
import {DevelopmentSheetMutationTypes} from "@/store/modules/development-sheet/mutation-types";

export type Mutations<S = State> = {
  [DevelopmentSheetMutationTypes.SET_ACTIVE_TAB](state: S, payload: any): void
  [DevelopmentSheetMutationTypes.CLEAR_STATE](state: S, payload?: null): void
  [DevelopmentSheetMutationTypes.SET_DEFAULT_DATA](state: S, payload?: null): void
  [DevelopmentSheetMutationTypes.TOGGLE_ONLY_FOCUSED_BRANDS](state: S, isActive: boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
  [DevelopmentSheetMutationTypes.SET_ACTIVE_TAB](state: State, payload: any) {
    state.activeTab = payload
  },
  [DevelopmentSheetMutationTypes.CLEAR_STATE](state: State) {
    state.activeTab = state.sideBar[0]
  },
  [DevelopmentSheetMutationTypes.SET_DEFAULT_DATA](state: State) {
    state.activeTab = state.sideBar[0]
  },
  [DevelopmentSheetMutationTypes.TOGGLE_ONLY_FOCUSED_BRANDS](state: State, isActive: boolean) {
    state.onlyFocusedBrands = isActive
  }
};
