import {ActionTree, ActionContext} from 'vuex';
import {RootState, store} from '@/store';
import {State} from './state';
import {Mutations} from './mutations';
import { getDevice, getDeviceSize } from '@/components/types/deviceUtils';
import { DeviceActionTypes } from '@/store/modules/device/action-types';
import { DeviceMutationTypes } from '@/store/modules/device/mutation-types';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [DeviceActionTypes.SET_CURRENT_DEVICE]({commit, state}: AugmentedActionContext, width: any): any
  [DeviceActionTypes.SET_CURRENT_DEVICE_SIZE]({commit, state}: AugmentedActionContext, width: any): any
  [DeviceActionTypes.SET_CURRENT_DEVICE_HEIGHT]({commit, state}: AugmentedActionContext, height: any): any
}

export const actions: ActionTree<State, RootState> & Actions = {
  [DeviceActionTypes.SET_CURRENT_DEVICE]: ({ commit, state }, width) => {
    const device = getDevice(width);
    return state.currentDevice !== device
      ? commit(DeviceMutationTypes.SET_CURRENT_DEVICE, device)
      : null;
  },
  [DeviceActionTypes.SET_CURRENT_DEVICE_SIZE]: ({ commit, state }, width) => {
    const device = getDeviceSize(width);

    return (
      !(state.currentDeviceSize === device) &&
      commit(DeviceMutationTypes.SET_CURRENT_DEVICE_SIZE, device)
    );
  },
  [DeviceActionTypes.SET_CURRENT_DEVICE_HEIGHT]: ({commit}, height) => {
    store.commit(DeviceMutationTypes.SET_CURRENT_DEVICE_HEIGHT, height)
  }
};
