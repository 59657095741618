import {MutationTree} from 'vuex';
import {State} from './state';
import { DeviceMutationTypes } from './mutation-types'

export type Mutations<S = State> = {
  [DeviceMutationTypes.SET_CURRENT_DEVICE](state: S, payload: any): void
  [DeviceMutationTypes.SET_CURRENT_DEVICE_SIZE](state: S, payload: any): void
  [DeviceMutationTypes.SET_CURRENT_DEVICE_HEIGHT](state: S, payload: any): void
}

export const mutations: MutationTree<State> & Mutations = {
  [DeviceMutationTypes.SET_CURRENT_DEVICE]: (state, payload) => (state.currentDevice = payload),
  [DeviceMutationTypes.SET_CURRENT_DEVICE_SIZE]: (state, payload) => (state.currentDeviceSize = payload),
  [DeviceMutationTypes.SET_CURRENT_DEVICE_HEIGHT]: (state, payload) => (state.currentDeviceHeight = payload),
};
