export interface LayoutRequests {
  createdAt: string;
  id: string;
  name: string;
  user: string;
}

export interface Certificate {
  datetimeUploaded: string;
  expiredAt: string;
  filename: string;
  id: number;
  link: string;
  number: string;
  user: string;
  isExpired?: boolean
  fileDate: string
  type: CertificateTypes,
}

export interface RequestPayload {
  article: string;
  numberOfRegulation: string;
  pgId: number;
  psgId: number;
  brandId: number;
}

export interface Request {
  article: string,
  brandId: number,
  id: number,
  numberOfRegulation: string,
  psgId: number,
  pgId: number,
  psg: string,
  pg: string,
  brand: string,
  status: CertificateRequestsStatus,
  requestId: string | null,
  backgroundColor?: string
}

export interface ErrorFile { description: string; rowNum: number }

export interface CertificateProductGroup {
  id: number,
  name: string,
  subgroups: Array<CertificateProductSubGroup>
}

export interface CertificateProductSubGroup {
  code: string,
  id: number,
  name: string,
  pgId: number,
}


export enum CertificateRequestsStatus {
  REQUESTED = 'В заявках',
  PENDING = 'Ожидает сертификации',
  SUCCESS = 'Сертифицированный'
}

export interface DocumentRequests {
  ids: number[],
  withApproved: boolean,
  isPending: boolean,
  brands?: string[],
  psgIds?: number[],
}

export interface ApproveRequests {
  requestId: string,
  numberOfCertification: string,
  createdAt: any,
  expiredAt: any,
}

export enum CertificateTypes {
  CERTIFICATE = "CERTIFICATE",
  REFUSAL = "REFUSAL",
  DECLARATION = "DECLARATION"
}

export const certificateTypes = {
  [CertificateTypes.CERTIFICATE]: 'Сертификат',
  [CertificateTypes.REFUSAL]: 'Отрицательное решение',
  [CertificateTypes.DECLARATION]: 'Декларация соответствия',
}
