export type State = {
  isError: boolean;
  loggedUser: {[key: string]: any} | null,
  permissions: {[key: string]: object}
}

export const state: State = {
  isError: false,
  loggedUser: null,
  permissions: { //убрать анализ цен для вьюверов
    create_article: { //+
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: true,
      SUPPORT_MANAGER: true,
      SALES_MANAGER: true
    }, //АРТИКУЛ обработано
    search_article: {
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: true,
      SUPPORT_MANAGER: true,
      SALES_MANAGER: true,
    },
    edit_article: { //+
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true, //Кроме смены статуса товара
      PROCUREMENT_MANAGER: false, //Кроме смены статуса товара
      SUPPORT_MANAGER: false, //Кроме смены статуса товара
      SALES_MANAGER: false, //кроме смены статуса товара
    },
    edit_article_status: {
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    },
    add_duplicate_cross: { //+
      ADMIN: true,
      ADMIN_DEVELOP: false,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    },
    create_prices_list: { // не показывать анализ цен?
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: true,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    },
    filter_result_search_article: { // ?Фильтрация результов поиска по артикулам есть ли такой функциал
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: true,
      SUPPORT_MANAGER: true,
      SALES_MANAGER: true,
    },
    audit: { //+
      ADMIN: false,
      ADMIN_DEVELOP: false,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    },
    create_request_prices: { // +
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: true,
      SUPPORT_MANAGER: true,
      SALES_MANAGER: true,
    },
    prices_action: { // +
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: true,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: true,
    },
    prices_to_excel: {
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: true,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: true,
    },
    create_mastering_sheet: { // +
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    }, //ЛИСТЫ ОСВОЕНИЯ Пересекается с созданием артикула
    edit_mastering_sheet: {
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    },
    request_prices_for_article: {
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    },
    search_mastering_sheet: { // + поиск и просмотр листа освоения
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    },
    request_price_analysis_master_sheet: { // функционал ?
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    },
    //bad (
    create_percentage_sheet: { // не понятно, как именно создаем -
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    }, //ЛИСТЫ ПРОЦЕНКИ если мы не админ или редактор, то нет смысла показывать Анализ цент
    edit_percentage_sheet: { // непонятно как редактировать
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    }, //ЛИСТЫ ПРОЦЕНКИ
    delete_percentage_sheet: { // тоже самое
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    }, //ЛИСТЫ ПРОЦЕНКИ
    search_percentage_sheet: {
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    }, //ЛИСТЫ ПРОЦЕНКИ
    request_price_analysis_percentage_sheet: {
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false,
    }, //ЛИСТЫ ПРОЦЕНКИ
    //bad (
    search_vehicles: { // +
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: true,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    add_new_vehicles: { // +
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    }, //СПРАВОЧНИК АВТО обработан
    blocked_vehicles: { // +
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    edit_vehicles: { // +
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    }, //СПРАВОЧНИК АВТО обработан
    export_crosses: { // Нет такого пермишена
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    }, //Страница экспорта
    edit_user: { //++
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    }, // Профиль пользователя
    edit_brands_article: {
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    delete_mastering_sheet: {
      ADMIN: true,
      ADMIN_DEVELOP: false,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    upload_price: {
      ADMIN: false,
      ADMIN_DEVELOP: false,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    comparison_report: {
      ADMIN: false,
      ADMIN_DEVELOP: false,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    delete_prices_import_file: {
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    edit_product_group: {
      ADMIN: true,
      ADMIN_DEVELOP: true,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    view_suppliers_on_prices_analyses: {
      ADMIN: true,
      ADMIN_DEVELOP: false,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    rrc: {
      ADMIN: true,
      ADMIN_DEVELOP: false,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    steal_info_gbs: {
      ADMIN: true,
      ADMIN_DEVELOP: false,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    delete_crosses: {
      ADMIN: true,
      ADMIN_DEVELOP: false,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    update_status: {
      ADMIN: true,
      ADMIN_DEVELOP: false,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    },
    certificates: {
      ADMIN: true,
      ADMIN_DEVELOP: false,
      DEVELOPMENT_MANAGER: false,
      PROCUREMENT_MANAGER: false,
      SUPPORT_MANAGER: false,
      SALES_MANAGER: false
    }
  }
};
