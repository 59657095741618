export enum ArticleMutationTypes {
  SET_PARAMETER = 'SET_PARAMETER',
  GET_ARTICLES = 'GET_ARTICLES',
  SET_ARTICLES = 'SET_ARTICLES',
  NEXT_PAGE_ARTICLES = 'NEXT_PAGE_ARTICLES',
  PREVIOUS_PAGE_ARTICLES = 'PREVIOUS_PAGE_ARTICLES',
  SET_SORT = 'SET_SORT',
  CLEAR_STATE = 'CLEAR_STATE',
  SET_GROUP = 'SET_GROUP',
  SET_SUB_GROUP = 'SET_SUB_GROUP',
  SET_ARTICLES_PAGE = 'SET_ARTICLES_PAGE',
  SET_LOADING = 'SET_LOADING',
  CHANGE_PAGE = 'CHANGE_PAGE',
  DELETE_PARAMETER = 'DELETE_PARAMETER'
}
