import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';
import { AugmentedActionContext } from './index';
import certificates from '@/services/certificates';
import { IPageableContent } from '@/models/PageableModel';
import {
  ApproveRequests,
  Certificate,
  CertificateProductGroup,
  CertificateRequestsStatus,
  CertificateTypes,
  DocumentRequests,
  LayoutRequests,
  Request,
  RequestPayload
} from '@/models/CertificateModel';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import GenerateColorRGBA from '@/components/utils/GenerateColorRGBA';

export interface Actions {
  addCertificateProductSubGroup({ commit, state }: any, subProductGroupPayload: any): any
  updateCertificateProductGroup({ commit, state }: any, productGroupPayload: any): any
  addCertificateProductGroup({ commit }: any, payload: any): any
  fetchCertificateProductGroups({ commit }: any, pageable: any): Promise<IPageableContent<CertificateProductGroup>>
  requestsFileUpload({ commit }: any, data: any): void
  fetchRequests({ commit }: any, pageable: any): Promise<IPageableContent<Request>>
  addRequest({ commit }: any, requestPayload: Array<RequestPayload>): any
  certificatesFileUpload({ commit }: any, file: File): void
  checkCertificateArticle({ commit }: AugmentedActionContext, articleName: string): any
  fetchArticleCertificates({ commit }: AugmentedActionContext, articleName: string): void
  fetchCertificates({ commit }: AugmentedActionContext, pageable: any): Promise<IPageableContent<Certificate>>
  deleteCertificate({ commit }: AugmentedActionContext, certificateId: number): void
  addCertificateFile({ commit }: any, data: any): void
  deleteCertificateProductGroup({ commit }: AugmentedActionContext, id: number): void
  deleteCertificateProductSubGroup({ commit }: AugmentedActionContext, productSubGroup: any): void
  updateCertificateProductSubGroup({ commit, state }: AugmentedActionContext, productSubGroupPayload: any): void
  deleteLayoutRequests({ commit, state }: AugmentedActionContext, layoutId: string): void
  exportFile({ commit, state }: AugmentedActionContext, payload: { blobType: string, fileName: string, data: any}): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async updateCertificateProductSubGroup({ commit, state }, productSubGroupPayload: any) {
    try {
      const productSubGroup = await certificates.updateCertificateProductSubGroup(productSubGroupPayload)
      commit('replaceCertificateProductSubGroup', productSubGroup)
    } catch (error) {
      console.error(error)
    }
  },
  async deleteCertificateProductSubGroup({ commit }, productSubGroup: any) {
    try {
      await certificates.deleteCertificateProductSubGroup(productSubGroup.id)
      commit('deleteCertificateProductSubGroup', productSubGroup)
    } catch (error) {
      console.log(error)
    }
  },
  async addCertificateProductSubGroup({ commit, state }, subProductGroupPayload: any) {
    try {
      const productSubGroup = await certificates.addCertificateProductSubGroup(subProductGroupPayload)
      commit('addCertificateProductSubGroup', productSubGroup)
    } catch (error) {
      console.error(error)
    }
  },
  async updateCertificateProductGroup({ commit, state }, productGroupPayload: any) {
    try {
      const productGroup = await certificates.updateCertificateProductGroup(productGroupPayload)
      commit('replaceCertificateProductGroup', productGroup)
    } catch (error) {
      console.error(error)
    }
  },
  async deleteCertificateProductGroup({ commit }, id: number) {
    try {
      await certificates.deleteCertificateProductGroup(id)
      commit('deleteCertificateProductGroup', id)
    } catch (error) {
      console.log(error)
    }
  },
  async addCertificateProductGroup({ commit, state }, productGroupPayload: any) {
    try {
      const productGroup = await certificates.addCertificateProductGroup(productGroupPayload)
      commit('setCertificateProductGroups', [
        ...state.certificateProductGroups,
        productGroup
      ])
    } catch (error) {
      console.error(error)
    }
  },
  async fetchCertificateProductGroups({ commit }, payload: any): Promise<IPageableContent<CertificateProductGroup>> {
    const listProductGroups = await certificates.fetchCertificateProductGroups(payload.pageNumber, payload.pageSize)

    commit('setCertificateProductGroups', listProductGroups.content)
    return listProductGroups
  },
  async requestsFileUpload({ commit, dispatch }, data: any) {
    const formData = new FormData()

    for (let index = 0; index < data.files.length; index += 1) {
      formData.append('file', data.files[index]);
      formData.append('type', data.type);
      // formData.append('pgId', data.productGroup.id)
      // formData.append('psgId', data.productSubGroup.id)
      // formData.append('brandId', data.brand.brandId)
    }

    return await certificates.requestsUpload(formData).then((errors) => {
      commit('setUploadFileErrors', errors)
    })
  },
  async deleteLayoutRequests({ commit }, layoutId: string) {
    try {
      await certificates.deleteLayoutRequests(layoutId)
      commit('deleteLayoutRequests', layoutId)
    } catch (error) {
      console.log(error)
    }
  },
  async fetchLayoutsRequests({ commit }, pageable: any): Promise<IPageableContent<LayoutRequests>> {
    const layoutsRequest = await certificates.certificatesLayoutsRequests(pageable.pageNumber, pageable.pageSize)
    commit('setLayoutsRequests', layoutsRequest.content)
    return layoutsRequest
  },
  async fetchRequests({ commit }, pageable: any): Promise<IPageableContent<Request>> {
    const listRequests = await certificates.fetchRequests(pageable.pageNumber, pageable.pageSize, pageable.filters.status, pageable.type)
    const requestStatus: { [x: string]: any } = CertificateRequestsStatus
    const listRequestsWithBgColor = listRequests.content.map((request: Request, index: number) => {

      return {
        ...request,
        colorCell: requestStatus[request.status] === CertificateRequestsStatus.PENDING
          ? 'grey'
          : ''
      }
    })

    listRequests.content = listRequestsWithBgColor
    commit('setRequests', listRequestsWithBgColor)

    return listRequests
  },
  async addRequest({ commit }, requestPayload: Array<RequestPayload>) {
    const request = await certificates.addRequest(requestPayload)
    commit('setRequest', ...request)
  },
  async certificatesFileUpload({ commit }, file: File) {
    const formData = new FormData()
    formData.append('file', file)

    return await certificates.certificatesUpload(formData)
  },
  async checkCertificateArticle({ commit }, articleName: string) {
    const articleExist = await certificates.checkCertificate(articleName)
    return articleExist
  },
  async fetchArticleCertificates({ commit }, articleName: string) {
    try {
      commit('setLoadingCertificates', true)
      const articleCertificates = await certificates.getArticleCertificates(articleName)
      const transformData = articleCertificates.map((articleCertificate: Certificate) => ({
        ...articleCertificate,
        isExpired: new Date(articleCertificate.expiredAt).getTime() < new Date().getTime()
      }))

      commit('setCertificates', transformData)
      commit('setLoadingCertificates', false)
    } catch (error) {
      console.log(error)
    }
  },
  async addCertificateFile({ commit }, data: any) {
    try {
      const formData = new FormData()
      // const date = data.range
      console.log('Data', data)
      for (let index = 0; index < data.files.length; index += 1) {
        formData.append('extension', data.files[index].name.split('.').pop());
        formData.append('file', data.files[index]);
        formData.append('name', data.files[index].name.split('.').shift());
        // formData.append('number', data.number);
        formData.append('id', data.id);
        formData.append('type', data.type)

        // formData.append('expiredAt', convertSimpleDate(date));
      }

      const certificate = await certificates.addCertificateFile(formData)
      commit('replaceCertificate', certificate)
      commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
    } catch (error) {
      console.error(error)
    }
  },
  async certificateExcelReport({ commit }, payload: any) {
    const table = await certificates.certificateReport(payload)
    const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `certificates.xls`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
  async fetchCertificates({ commit }, options: any,): Promise<IPageableContent<Certificate>> {
    const listCertificates = await certificates.fetchListCertificates(options.pageNumber, options.pageSize, options.type)
    const transformData = listCertificates.content.map((certificate: Certificate) => ({
      ...certificate,
      isExpired: new Date(certificate.expiredAt).getTime() < new Date().getTime()
    }))

    commit('setCertificates', transformData)

    return {
      ...listCertificates,
      content: [...transformData]
    }
  },
  async deleteCertificate({ commit }, certificateId: number) {
    try {
      await certificates.deleteCertificate(certificateId)
      commit('deleteCertificate', certificateId)
    } catch (error) {
      console.error(error)
    }
  },
  async generateDocumentRequest({ commit }, payload: DocumentRequests) {
    try {
      const table = await certificates.documentRequest(payload)
      const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `requests.docx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error(error)
    }
  },
  async generateDocumentApprovedRequest({ commit }, payload: ApproveRequests) {
    try {
      const table = await certificates.documentRequestApprove(payload)
      const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `requests.docx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error)
    }
  },
  async changeRequestsStatus({ commit }, payload: DocumentRequests) {
    try {
      await certificates.documentRequest(payload)
    } catch (error) {
      console.error(error)
    }
  },
  async changeRequestsStatusToApprove({ commit }, payload: ApproveRequests) {
    await certificates.documentRequestApprove(payload)
  },
  exportFile({ commit },
    {
      data,
      blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      fileName = 'report.xls'
    }: { data: any, fileName: string, blobType: string  }
  ) {
    try {
      const blob = new Blob([data], { type: blobType })
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error(error)
    }
  },
};
