import {MutationTree} from 'vuex';
import {State} from './state';
import {MessageModalMutationTypes} from './mutation-types';

export type Mutations<S = State> = {
  [MessageModalMutationTypes.OPEN_MESSAGE_MODAL](state: S, payload: string): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MessageModalMutationTypes.OPEN_MESSAGE_MODAL](state: State, payload: string) {
    state.type = payload;
    let modal = document.querySelector<HTMLElement>('#modal-message')!;
    modal.style.display = 'flex';
    modal.style.zIndex = '3'

    setTimeout(() => {
      modal.style.display = 'none';
      state.type = 'ERROR'
    }, 1600)
  }
};
