import {ActionContext, ActionTree} from 'vuex';
import {RootState} from '@/store';
import {State} from './state';
import {Mutations} from './mutations';
import {ArticleActionTypes} from './action-types';
import articles from "@/services/articles";
import params from "@/services/params";
import certificates from '@/services/certificates';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ArticleActionTypes.SEARCH_ARTICLES_BY_PARAMETERS]({commit}: AugmentedActionContext, payload: object): Promise<any>;

  [ArticleActionTypes.GET_ARTICLES]({commit}: AugmentedActionContext): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ArticleActionTypes.SEARCH_ARTICLES_BY_PARAMETERS]({commit}, payload: object) {
    return new Promise((resolve, reject) => {
      params.searchByParameters(payload).then(response => {
        resolve(response)
      }).catch(() => {
        reject()
      })
    })
  },
  async [ArticleActionTypes.GET_ARTICLES]() {
    return new Promise((resolve, reject) => {
      articles.getArticles().then(response => {
        resolve(response)
      }).catch(() => {
        reject()
      })
    })
  },
  async deleteArticles({ commit }, articlesForDelete: string[]) {
    try {
      await articles.deleteArticles(articlesForDelete)
    } catch (error) {
      console.error(error)
    }
  },
  async renameArticles({ commit }, data: any) {
    try {
      const formData = new FormData()
      formData.append('file', data.files[0]);
      await articles.renameArticles(formData)
    } catch (error) {
      console.error(error)
    }
  }
};
