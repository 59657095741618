import {GetterTree} from 'vuex';
import {RootState} from '@/store';
import { FilterNames, State, TypeFilterNames } from './state';

export type Getters = {
  getFilteredItems(state: State): any;
  getVehicles(state: State): object;
  getVehiclesBrands(state: State): object;
  getVehiclePageable(state: State): object;
  getVehicleSort(state: State): string;
  getVehicleBlocked(state: State): boolean,
  getVehicleTypes(state: State): object[],
  getVehicleCategories(state: State): object[],
  getFilters(state: State): object[],
}

export const getters: GetterTree<State, RootState> & Getters = {
  getVehicles: (state) => state.vehicles,
  getVehiclesBrands: (state) => state.vehiclesBrands,
  getVehiclePageable: (state) => state.pageable,
  getVehicleSort: (state) => state.sort,
  getVehicleBlocked: (state) => state.vehicleIsBlocked,
  getVehicleTypes: (state) => state.vehicleTypes,
  getVehicleCategories: (state) => state.vehicleCategories,
  getFilteredItems(state: State): any {
    if (Object.keys(state.vehicles).length) {
      return state.vehicles.filter((vehicle: any) => {
        return Object
          .keys(state.filters)
          .filter((filterName: string) => {
            return !!state.filters[filterName as TypeFilterNames].conditionFilter
          })
          .every((filterName: string) => {
            const currentFilterItem = state.filters[filterName as TypeFilterNames]

            if (currentFilterItem && currentFilterItem.conditionFilter) {
              return currentFilterItem.conditionFilter(vehicle)
            }
          })
      })
    }

    return []
  },
  getFilters(state: State): any {
    return state.filters
  }
};
