import { ActionTree } from 'vuex';
import { RootState, store } from '@/store';
import { State } from './state';
import systemProductGroups from '@/services/systemProductGroups';
import {
  AttachSystemPayload,
  DetachProductGroupPayload,
  SystemProductGroups
} from '@/models/SystemModel';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export interface Actions {
  fetchSystemsGroups({ commit }: any): Promise<void>
  fetchSystems({ commit }: any): Promise<void>
  attachSystem({ commit }: any, payload: AttachSystemPayload): Promise<void>
  addSystemProductGroups({ commit }: any, payload: { name: string }): Promise<void>
  updateSystemProductGroups({ commit }: any, payload: { name: string, id: number }): Promise<void>
  detachSystem({ commit }: any, payload: AttachSystemPayload): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async updateSystemProductGroups({ commit }: any, payload: { name: string, id: number }): Promise<void> {
    try {
      const updatedSystem = await systemProductGroups.updateSystem(payload)
      commit('replaceSystemProductGroups', updatedSystem)
    } catch (error) {
      console.error(error)
    }
  },
  async addSystemProductGroups({ commit }: any, payload: { name: string }): Promise<void> {
    try {
      const addedSystem = await systemProductGroups.addSystem(payload)
      commit('addSystemProductGroups', addedSystem)
    } catch (error) {
      console.error(error)
    }
  },
  async fetchSystemsGroups({ commit }: any): Promise<any> {
    try {
      const systemsProductGroups = await systemProductGroups.allSystemsGroups()

      const sortItems = (prev: SystemProductGroups, next: SystemProductGroups) => {
        if (prev.system.name > next.system.name) return -1;
        if (prev.system.name < next.system.name) return 1;
        return 0
      }

      const sortedSystems = systemsProductGroups.sort(sortItems)
      commit('setSystemsProductGroups', sortedSystems)
    } catch (error) {
      console.error(error)
    }
  },
  async fetchSystems({ commit }: any): Promise<void> {
    try {
      await systemProductGroups.getSystems()
    } catch (error) {
      console.log(error)
    }
  },
  async attachSystem({ commit, store }: any, payload: AttachSystemPayload) {
    return await systemProductGroups.attachSystem(payload)
  },
  async detachSystem({ commit, dispatch }: any, payload: AttachSystemPayload) {
    try {
      await systemProductGroups.detachSystem(payload)
      await dispatch('fetchSystemsGroups')
    } catch (error) {
      console.error(error)
    }
  },
  async deleteSystem({ commit }, systemId: number) {
    try {
      await systemProductGroups.deleteSystem(systemId)
      commit('deleteSystem', systemId)
      commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
    } catch (error) {
      commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      console.error(error)
    }
  }
};
