export enum VehicleMutationTypes {
  GET_VEHICLES = 'GET_VEHICLES',
  GET_VEHICLES_BRANDS = 'GET_VEHICLES_BRANDS',
  SET_VEHICLES = 'SET_VEHICLES',
  SET_VEHICLES_BRANDS = 'SET_VEHICLES_BRANDS',
  GET_VEHICLES_MODELS_BY_BRAND = 'GET_VEHICLES_MODELS_BY_BRAND',
  SEARCH_VEHICLES_BY_PARAMETERS = 'SEARCH_VEHICLES_BY_PARAMETERS',
  NEXT_PAGE_VEHICLES = 'NEXT_PAGE_VEHICLES',
  PREVIOUS_PAGE_VEHICLES = 'PREVIOUS_PAGE_VEHICLES',
  SET_SORT = 'SET_SORT',
  SET_SIZE = 'SET_SIZE',
  SET_VEHICLES_BLOCKED = 'SET_VEHICLES_BLOCKED',
  SET_FILTER = 'SET_FILTER',
  SET_OPTIONS_FILTER = 'SET_OPTIONS_FILTER',
}
