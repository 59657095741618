import {GetterTree} from 'vuex';
import {RootState} from '@/store';
import {State} from './state';

export type Getters = {
  getSideBarDevelopmentSheet(state: State): Array<any>
  getActiveTabDevelopmentSheet(state: State): any
  onlyFocusedBrands(state: State): boolean
}

export const getters: GetterTree<State, RootState> & Getters = {
  getSideBarDevelopmentSheet: (state) => state.sideBar,
  getActiveTabDevelopmentSheet: (state) => state.activeTab,
  onlyFocusedBrands: (state) => state.onlyFocusedBrands
};
