import {httpClient} from "@/httpClient";
import {store} from "@/store";

async function updateBrand(brandId: any, payload: any) {
  const response = await httpClient.put(`/product/vehicles/brands/${brandId}`, payload);
  return response.data;
}

async function addNewBrand(payload: object) {
  const response = await httpClient.post(`/product/vehicles/brands`, payload);
  return response.data;
}

async function findBrandById(brandId: any) {
  const response = await httpClient.get(`/product/vehicles/brands/${brandId}`);
  return response.data;
}

//Разблокировать бренд
async function setUnblockedBrand(brandId: number) {
  const response = await httpClient.patch(`/product/vehicles/brands/${brandId}/unblock`);
  return response.data;
}

//Заблокировать бренд
async function setBlockedBrand(brandId: number) {
  const response = await httpClient.patch(`/product/vehicles/brands/${brandId}/block`);
  return response.data;
}

//Марки машин по названию
async function findByBrandIgnoreCaseContaining(brand: string, isBlocked?: any) {
  const response = await httpClient.get(`/product/vehicles/brands${isBlocked === 'ALL' ? '' : isBlocked ? '?blocked=BLOCKED' : '?blocked=UNBLOCKED'}${isBlocked === 'ALL' ? '?brand=' : '&brand='}${brand}`);
  // const response = await httpClient.get(`/product/vehicles/brands?blocked=${isBlocked ? 'BLOCKED' : 'UNBLOCKED'}&brand=${brand}`);
  return response.data;
}

//Марки машин по типу транспортного средства
async function findAllBrandsByType(type: any, isBlocked?: boolean) {
  let blocked = 'UNBLOCKED'
  // let blocked = 'BLOCKED'
  const response = await httpClient.get(`/product/vehicles/brands?blocked=${isBlocked ? 'BLOCKED' : 'UNBLOCKED'}&type=${type}`);
  return response.data;
}

//Марки машин
async function getAllVehiclesBrands(isBlocked?: any, type?: any) {
  const response = await httpClient.get(`/product/vehicles/brands${isBlocked === 'ALL' ? '' : isBlocked ? '?blocked=BLOCKED' : '?blocked=UNBLOCKED'}${type ? `?type=${type}` : ''}`);
  return response.data;
}

async function getVehicleByBrandId(brandId: any, page: number) {
  let pageable = store.getters.getVehiclePageable;
  let sort = store.getters.getVehicleSort;
  const response = await httpClient.get(`/product/vehicles?brandId=${brandId}&page=${page}&size=${pageable.size}&sort=id,${sort}`);
  return response.data;
}


export default {
  updateBrand,
  addNewBrand,
  findBrandById,
  setUnblockedBrand,
  setBlockedBrand,
  findByBrandIgnoreCaseContaining,
  findAllBrandsByType,
  getAllVehiclesBrands,
  getVehicleByBrandId,
}
