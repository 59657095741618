import {
  Certificate,
  CertificateProductGroup,
  ErrorFile, LayoutRequests,
  Request
} from '@/models/CertificateModel';

export type State = {
  certificates: Array<Certificate>,
  requests: Array<Request>,
  loadingCertificates: boolean,
  uploadFileErrors: Array<ErrorFile>,
  certificateProductGroups: Array<CertificateProductGroup>,
  layoutsRequests: Array<LayoutRequests>,
}

export const state: State = {
  certificates: [],
  requests: [],
  loadingCertificates: false,
  uploadFileErrors: [],
  certificateProductGroups: [],
  layoutsRequests: [],
};
