import {MutationTree} from 'vuex';
import {State} from './state';
import {
  Certificate,
  CertificateProductGroup,
  ErrorFile, LayoutRequests,
  Request
} from '@/models/CertificateModel';

export type Mutations<S = State> = {
  addCertificateProductSubGroup(state: S, productSubGroup: any): void;
  replaceCertificateProductGroup(state: S, id: number): void;
  replaceCertificateProductSubGroup(state: S, id: number): void;
  deleteCertificateProductGroup(state: S, id: number): void;
  deleteCertificateProductSubGroup(state: S, productSubGroup: any): void;
  setCertificateProductGroups(state: S, productGroups: CertificateProductGroup[]): void;
  setLoadingCertificates(state: S, isLoading: boolean): void;
  setCertificates(state: S, certificates: Array<Certificate>): void;
  setCertificate(state: S, certificate: any): void;
  deleteCertificate(state: S, certificateId: number): void;
  setRequests(state: S, requests: Array<Request>): void;
  setRequest(state: S, request: Request): void;
  setUploadFileErrors(state: S, errors: Array<ErrorFile>): void;
  replaceCertificate(state: S, certificate: Certificate): void;
  replaceCertificate(state: S, certificate: Certificate): void;
  setLayoutsRequests(state: S, layoutsRequests: Array<LayoutRequests>): void;
  deleteLayoutRequests(state: S, layoutId: string): void;
}

export const mutations: MutationTree<State> & Mutations = {
  replaceCertificateProductSubGroup(state: State, productSubGroup: any) {
    const indexProductGroup = state.certificateProductGroups.findIndex((value: any) => {
      return value.id === productSubGroup.pgId
    })

    const indexSubGroup = state
      .certificateProductGroups[indexProductGroup]
      .subgroups
      .findIndex((subgroup: any) => {
        return subgroup.id === productSubGroup.id
      })

    state
      .certificateProductGroups[indexProductGroup]
      .subgroups
      .splice(indexSubGroup, 1, productSubGroup)
  },
  deleteCertificateProductSubGroup(state: State, productSubGroup: any) {
    const indexProductGroup = state.certificateProductGroups.findIndex((value: any) => {
      return value.id === productSubGroup.pgId
    })

    const indexSubGroup = state
      .certificateProductGroups[indexProductGroup]
      .subgroups
      .findIndex((subgroup: any) => {
        return subgroup.id === productSubGroup.id
      })

    state
      .certificateProductGroups[indexProductGroup]
      .subgroups
      .splice(indexSubGroup, 1)
  },
  addCertificateProductSubGroup(state: State, productSubGroup: any) {
    const indexProductGroup = state.certificateProductGroups.findIndex((value: any) => {
      return value.id === productSubGroup.pgId
    })

    state.certificateProductGroups[indexProductGroup].subgroups.push(productSubGroup)
  },
  replaceCertificateProductGroup(state: State, productGroup: any) {
    const index = state.certificateProductGroups.findIndex((value: any) => {
      return value.id === productGroup.id
    })
    state.certificateProductGroups.splice(index, 1, productGroup)
  },
  deleteCertificateProductGroup(state: State, id: number) {
    state.certificateProductGroups = state.certificateProductGroups.filter((productGroup: CertificateProductGroup) => {
      return productGroup.id !== id
    })
  },
  setCertificateProductGroups(state: State, productGroups: CertificateProductGroup[]) {
    state.certificateProductGroups = [...productGroups]
  },
  setLoadingCertificates(state: State, isLoading: boolean) {
    state.loadingCertificates = isLoading
  },
  setCertificates(state: State, certificates: Array<Certificate>) {
    state.certificates = certificates
  },
  deleteCertificate(state: State, certificateId: number) {
    state.certificates = state.certificates.filter((certificate: Certificate) => {
      return certificate.id !== certificateId
    })
  },
  setCertificate(state: State, certificate: any) {
    state.certificates.push(certificate)
  },
  setRequests(state: State, requests: Array<Request>) {
    state.requests = requests
  },
  setRequest(state: State, request: Request) {
    state.requests.push(request)
  },
  setUploadFileErrors(state: State, errors: Array<ErrorFile>) {
    state.uploadFileErrors = errors
  },
  replaceCertificate(state: State, certificate: Certificate) {
    const index = state.certificates.findIndex((currentCertificate: Certificate) => {
      return currentCertificate.id === certificate.id
    })

    state.certificates.splice(index, 1, certificate);
  },
  setLayoutsRequests(state: State, layoutsRequests: Array<LayoutRequests>): void {
    state.layoutsRequests = layoutsRequests
  },
  deleteLayoutRequests(state: State, layoutId: string) {
    state.layoutsRequests = state.layoutsRequests.filter((layout: LayoutRequests) => {
      return layout.id !== layoutId
    })
  }
};
