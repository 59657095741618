export type State = {
  parameters: Array<any> // Параметры поиска
  articles: object // Артикулы
  pageable: { page: number, size: number } // Текущая страница и количество отображения артикулов
  sort: string // Сортировка (asc, desc)
  isLoading: boolean,
  group: any, // название группы для страницы с параметрами
  subGroup: any
}

export const state: State = {
  parameters: [],
  articles: {},
  pageable: {page: 0, size: 20},
  sort: 'asc',
  isLoading: false,
  group: null,
  subGroup: null,
};
