import {MutationTree} from 'vuex';
import {State} from './state';
import {ArticleMutationTypes} from './mutation-types';
import {ArticleActionTypes} from "./action-types";
import {store} from "@/store";
import {ConvertParameters} from "@/components/converters/articles";

export type Mutations<S = State> = {
  [ArticleMutationTypes.SET_PARAMETER](state: S, payload: any): void;
  [ArticleMutationTypes.SET_ARTICLES](state: S, payload: any): void;
  [ArticleMutationTypes.GET_ARTICLES](state: S, payload?: null): void;
  [ArticleMutationTypes.NEXT_PAGE_ARTICLES](state: S, payload?: null): void;
  [ArticleMutationTypes.PREVIOUS_PAGE_ARTICLES](state: S, payload?: null): void;
  [ArticleMutationTypes.SET_SORT](state: S, payload: string): void;
  [ArticleMutationTypes.CLEAR_STATE](state: S, payload?: null): void;
  [ArticleMutationTypes.SET_GROUP](state: S, payload?: string): void;
  [ArticleMutationTypes.SET_SUB_GROUP](state: S, payload?: string): void;
  [ArticleMutationTypes.SET_ARTICLES_PAGE](state: S, payload?: any): void;
  [ArticleMutationTypes.SET_LOADING](state: S, payload: boolean): void;
  [ArticleMutationTypes.DELETE_PARAMETER](state: S, parametersForDelete: Array<string>): void;
  [ArticleMutationTypes.CHANGE_PAGE](state: S, pageNumber: number): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [ArticleMutationTypes.SET_ARTICLES](state: State, payload: any) {
    state.articles = payload
  },
  [ArticleMutationTypes.DELETE_PARAMETER](state: State, parametersForDelete: Array<string>) {
    parametersForDelete.map((typeParameter: string) => {
      const index = state.parameters.findIndex((parameter: any) => parameter.type === typeParameter)

      if (index >= 0) {
        state.parameters.splice(index, 1)
      }
    })
    store.commit(ArticleMutationTypes.SET_ARTICLES_PAGE, 0)
    store.commit(ArticleMutationTypes.GET_ARTICLES)
  },
  [ArticleMutationTypes.SET_PARAMETER](state: State, payload: any) {
    let index = state.parameters.findIndex(o => o.type === payload.type);
    // Удаление
    if (index !== -1 && !payload.value) state.parameters.splice(index, 1);
    // Изменение
    else if (index !== -1) state.parameters.splice(index, 1, payload);
    // Добавление
    else if (payload.value) state.parameters.push(payload);
    store.commit(ArticleMutationTypes.SET_ARTICLES_PAGE, 0)
    store.commit(ArticleMutationTypes.GET_ARTICLES)
  },
  async [ArticleMutationTypes.GET_ARTICLES](state: State) {
    state.isLoading = true;
    // state.pageable.page = 0;
    try {
      let result = null;
      let parameters = ConvertParameters();
      if (Object.keys(parameters).length) {
        result = await store.dispatch(ArticleActionTypes.SEARCH_ARTICLES_BY_PARAMETERS, parameters)
      } else {
        result = await store.dispatch(ArticleActionTypes.SEARCH_ARTICLES_BY_PARAMETERS, {})
      }
      store.commit(ArticleMutationTypes.SET_ARTICLES, result);
      state.isLoading = false
    } catch (error) {
      state.isLoading = false
    }
  },
  async [ArticleMutationTypes.SET_ARTICLES_PAGE](state: State, payload: any) {
    state.pageable.page = payload
  },
  async [ArticleMutationTypes.CHANGE_PAGE](state: State, pageNumber) {
    state.isLoading = true;
    state.pageable.page = pageNumber;
    try {
      let result = null;
      let parameters = ConvertParameters();
      if (Object.keys(parameters).length) {
        result = await store.dispatch(ArticleActionTypes.SEARCH_ARTICLES_BY_PARAMETERS, parameters)
      } else {
        result = await store.dispatch(ArticleActionTypes.GET_ARTICLES)
      }
      store.commit(ArticleMutationTypes.SET_ARTICLES, result);
      state.isLoading = false
    } catch (error) {
      state.isLoading = false
    }
  },
  async [ArticleMutationTypes.NEXT_PAGE_ARTICLES](state: State) {
    state.isLoading = true;
    state.pageable.page = state.pageable.page + 1;
    try {
      let result = null;
      let parameters = ConvertParameters();
      if (Object.keys(parameters).length) {
        result = await store.dispatch(ArticleActionTypes.SEARCH_ARTICLES_BY_PARAMETERS, parameters)
      } else {
        result = await store.dispatch(ArticleActionTypes.GET_ARTICLES)
      }
      store.commit(ArticleMutationTypes.SET_ARTICLES, result);
      state.isLoading = false
    } catch (error) {
      state.pageable.page = --state.pageable.page;
      state.isLoading = false
    }
  },
  async [ArticleMutationTypes.PREVIOUS_PAGE_ARTICLES](state: State) {
    state.isLoading = true;
    state.pageable.page = state.pageable.page - 1;
    try {
      let result = null;
      let parameters = ConvertParameters();
      if (Object.keys(parameters).length) {
        result = await store.dispatch(ArticleActionTypes.SEARCH_ARTICLES_BY_PARAMETERS, parameters)
      } else {
        result = await store.dispatch(ArticleActionTypes.GET_ARTICLES)
      }
      store.commit(ArticleMutationTypes.SET_ARTICLES, result);
      state.isLoading = false
    } catch (error) {
      state.pageable.page = ++state.pageable.page;
      state.isLoading = false
    }
  },
  [ArticleMutationTypes.SET_SORT](state: State, payload: string) {
    state.sort = payload;
    store.commit(ArticleMutationTypes.GET_ARTICLES)
  },
  [ArticleMutationTypes.CLEAR_STATE](state: State) {
    state.parameters = [];
    state.pageable = {page: 0, size: 20};
    state.sort = 'asc'
  },
  [ArticleMutationTypes.SET_GROUP](state: State, payload: string) {
    state.group = payload;
  },
  [ArticleMutationTypes.SET_SUB_GROUP](state: State, payload: string) {
    state.subGroup = payload;
  },
  [ArticleMutationTypes.SET_LOADING](state: State, payload: boolean) {
    state.isLoading = payload
  }
};
