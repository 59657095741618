export type State = {
  currentDevice: any,
  currentDeviceSize: any,
  currentDeviceHeight: any
}

export const state: State = {
  currentDevice: null,
  currentDeviceSize: null,
  currentDeviceHeight: null
};
