import {httpClient} from "@/httpClient";
import {store} from "@/store";
import axios from 'axios';

// Карточка артикула (Параметры - обновление)
function updateParameter(payload: object) {
  return httpClient.put(`/article/${localStorage.getItem('cid')}/params`, payload).then(response => response.data)
}

function updateParam(pgId: any, payload: any) {
  return httpClient.put(`/product/${localStorage.getItem('cid')}/param/${pgId}`, payload)
}

function getAllParameters(page: string | number) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/param?nameLike=&size=200&page=${page}&sort=name,asc`).then(response => response.data)
}

function getAllParamsByProductGroup(pgId: string | number) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/param?pgId=${pgId}&sort=desc,asc`).then((response) => response.data)
}

// Поиск артикулов по параметрам
function searchByParameters(payload: object) {
  let pageable = store.getters.getPageable;
  let sort = store.getters.getSort;
  return httpClient.post(`/article/${localStorage.getItem('cid')}/search?page=${pageable.page}&size=${pageable.size}&sort=article,${sort}`, payload).then(response => response.data)
}

function getAllByProductGroup(productGroupId: number | string) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/param?pgId=${productGroupId}`).then(response => response.data)
}

// Карточка артикула (Параметры - получение параметров товарной группы по названию)
function searchParametersByName(payload: string, size = 20) {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/param?nameLike=${payload}&size=${size}`).then(response => response.data)
}

// Карточка артикула (Параметры - создание)
function createParameter(payload: object) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/params`, payload).then(response => response.data)
}

function getParameterByKey(key: string, item: string, option: string) {
  return httpClient.get(`article/${localStorage.getItem('cid')}/${key}/${item}${option ? `/${option}` : ''}`).then(response => response.data)
}

// Удаление параметра
function deleteParameter(id: string) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/params/${id}`).then(response => response.data)
}

function deleteParam(paramId: any) {
  return httpClient.delete(`/product/${localStorage.getItem('cid')}/param/${paramId}`).then(response => response.data)
}

function addNewParam(payload: any) {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/param`, payload).then(response => response.data)
}

function setQueue(productGroupId: any, payload: any) {
  return httpClient.put(`/product/${localStorage.getItem('cid')}/param/queue/${productGroupId}`, payload)
}

function setLogistic(payload: any) {
  return httpClient.patch(`/product/${localStorage.getItem('cid')}/param/logistic`, payload).then(response => response.data)
}

function updateVisibleOneParam(productGroupId: any, isVisible: any) {
  return httpClient.patch(`/product/${localStorage.getItem('cid')}/param/visible`, {
    paramIds: [productGroupId],
    visible: isVisible
  }).then(response => response && response.data && response.data[0])
}

function exportTemplateParam(pgId: any) {
  return `${axios.defaults.baseURL}/product/${localStorage.getItem('cid')}/param/import-template?pgId=${pgId}`
}

async function importParams(payload: any) {
  await httpClient.post(`/product/${localStorage.getItem('cid')}/param/submit`, payload)
}

export default {
  updateParameter,
  updateParam,
  getAllParameters,
  getAllParamsByProductGroup,
  searchByParameters,
  searchParametersByName,
  createParameter,
  getParameterByKey,
  deleteParameter,
  addNewParam,
  getAllByProductGroup,
  deleteParam,
  setQueue,
  setLogistic,
  updateVisibleOneParam,
  exportTemplateParam,
  importParams,
}
