// Статус
export enum Status {
  ACTIVE = 'Действующий',
  PREPARING = 'В подготовке',
  ARCHIVED = 'Архивный',
  TEMPORARY = 'Временный',
  TEMPORARY_NOT_APPROVED = 'Временный, не прошел согласование',
  REPLACED = 'Заменен',
  NOT_PRODUCED = 'Не производим'
}

export type StatusType = keyof typeof Status

// Категория
export enum Category {
  FOREIGN = 'Иномарка',
  LOCAL = 'Отечка',
  OTHER = 'Прочее'
}

// Тип
export enum Type {
  EXTERNAL = 'Внешний',
  INTERNAL = 'Внутренний',
  SECONDARY = 'Вспомогательный'
}

export enum SupplierType {
  DISTRIBUTOR = 'DISTRIBUTOR',
  MANUFACTURER = 'MANUFACTURER',
  NONE = 'NONE'
}


// Направления
export enum Direction {
  // OE = 'OE кроссы / Входит в - Состоит из',
  HARD = 'Жесткие кроссы',
  TREE = 'OE кроссы',
  TREE_CONSISTS = 'OE кроссы / Входит в',
  TREE_INCLUDE = 'OE кроссы / Состоит из',
}

export enum TecdocDirection {
  TECDOC = 'Кроссы для TecDoc',
  APPLIC = 'Применяемость для TecDoc'
}

export type CombineDirectionKey = (typeof Direction) & (typeof TecdocDirection);

export enum DirectionKey {
  HARD = 'HARD',
  TREE = 'TREE'
}

export enum TecdocDirectionKey {
  TECDOC = 'TECDOC',
  APPLIC = 'APPLIC'
}

// Вид
export enum View {
  SIMPLEX = 'Простой',
  COMPOSITE = 'Составной'
}

export enum ArticleParameters {
  ARTICLE = 'Артикул',
  PRODUCT_GROUP = 'Товарная группа',
  STATUS = 'Статус',
  APPLICABILITY = 'Применяемость',
  CATEGORY = 'Категория'
}

export enum VehicleParameters {
  BRAND = 'Марка',
  MODEL = 'Модель авто',
  V_ENGINE = 'V двигателя',
  FUEL_TYPE = 'Тип топлива',
  POWER = 'Мощность',
  RELEASE = 'Выпуск',
  BODY_TYPE = 'Тип кузова',
  MODIFICATION = 'Модификация',
}

export enum UserParameters {
  COMPANY = 'Компания',
  EMAIL = 'Электронный адрес',
  ROLE = 'Роль',
  STATUS = 'Статус',
  USERNAME = 'Имя пользователя',
}

export enum ApplicabilityParameters {
  BRAND = 'Марка',
  MODEL = 'Модель авто',
  V_ENGINE = 'V двигателя',
  FUEL_TYPE = 'Тип топлива',
  POWER = 'Мощность',
  RELEASE = 'Выпуск',
  BODY_TYPE = 'Тип кузова',
  MODIFICATION = 'Модификация',
  MOTOR_CODE = 'Код двигателя'
  // PARAMETERS = 'Параметры'
}

export enum Roles {
  ADMIN = 1,
  ADMIN_DEVELOP = 2,
  SALES_MANAGER = 3,
  DEVELOPMENT_MANAGER = 4,
  PROCUREMENT_MANAGER = 5,
  SUPPORT_MANAGER = 6
}

export enum RolesEnum {
  ADMIN = "ADMIN",
  ADMIN_DEVELOP = "ADMIN_DEVELOP",
  SALES_MANAGER = "SALES_MANAGER",
  DEVELOPMENT_MANAGER = "DEVELOPMENT_MANAGER",
  PROCUREMENT_MANAGER = "PROCUREMENT_MANAGER",
  SUPPORT_MANAGER = "SUPPORT_MANAGER"
}

export enum Categories {
  MOTORBIKE = 'Мотоциклы',
  PASSENGER = 'Легковые',
  COMMERCIAL = 'Лёгкие коммерческие',
  TRUCK_AND_BUS = 'Грузовые и автобусы',
  TRACTOR_AND_SPECIAL = 'Тракторы и спецтехника',
}

export enum ReasonComparison {
  STANDARD_PRICES_NOT_FOUND = 'STANDARD_PRICES_NOT_FOUND',
  STANDARD_ARTICLE_NOT_FOUND = 'STANDARD_ARTICLE_NOT_FOUND',
  BRAND_PRICES_NOT_FOUND = 'BRAND_PRICES_NOT_FOUND',
  BRAND_ARTICLE_NOT_FOUND = 'BRAND_ARTICLE_NOT_FOUND'
}

export type ReasonType = keyof typeof ReasonComparison

export enum CountComparison {
  countOfStandartPrices = 'Кол-во всех загруженных цен эталона',
  countOfBrandPrices = 'Кол-во всех загруженных цен бренда',
  noArticlesStandard = 'Кол-во цен эталона, на которых нет артикулов в базе',
  noArticlesBrand = 'Кол-во артикулов бренда, на которых есть цена и артикул эталона но нет артикула у нас в базе',
  noPricesBrand = 'Кол-во цен без бренда'
}

export type CountComparisonType = keyof typeof CountComparison


export enum GbsDownloadingStatuses {
  CRUSHED = 'Ошибка загрузки',
  STARTING = 'Начало загрузки',
  PENDING = 'Загрузка...',
  FINISHED = 'Завершено'
}


export type GbsDownloadingStatusesType = keyof typeof GbsDownloadingStatuses
