import {GetterTree} from 'vuex';
import {RootState} from '@/store';
import {State} from './state';

export type Getters = {
  getTypeMessageModal(state: State): string
}

export const getters: GetterTree<State, RootState> & Getters = {
  getTypeMessageModal: (state) => state.type,
};
