
import {defineComponent} from 'vue';
import { DeviceActionTypes } from '@/store/modules/device/action-types';

export default defineComponent({
  name: 'App',
  methods: {
    handleResize() {
      this.$store.dispatch(DeviceActionTypes.SET_CURRENT_DEVICE, window.outerWidth)
      this.$store.dispatch(DeviceActionTypes.SET_CURRENT_DEVICE_SIZE, window.outerWidth)
      this.$store.dispatch(DeviceActionTypes.SET_CURRENT_DEVICE_HEIGHT, window.innerHeight)
    },
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
})
