import {httpClient} from '@/httpClient';
import {store} from "@/store";
import axios, { AxiosResponse } from 'axios';

async function addVehicle(payload: object) {
  const response = await httpClient.post(`/product/vehicles`, payload);
  return response.data;
}

async function updateVehicle(id: string, payload: object) {
  // const response = await httpClient.put(`/product/${localStorage.getItem('cid')}/vehicles`);
  const response = await httpClient.put(`/product/vehicles/${id}`, payload);
  return response.data;
}

async function findVehicleByModelIdAndBrandId(brandId: string, modelId: string) {
  const response = await httpClient.get(`/product/vehicles?modelId=${modelId}`);
  return response.data;
}


async function findById(id: string) {
  const response = await httpClient.get(`/product/vehicles?id=${id}`);
  return response.data[0];
}

async function findVehicleByModelId(modelId: string) {
  const response = await httpClient.get(`/product/${localStorage.getItem('cid')}/vehicles?modelId=${modelId}`);
  return response.data;
}

async function findVehicleByModelIds(modelIds: Array<string | number>) {
  const response = await httpClient.post(`/product/vehicles/modelIds`, modelIds)
  return response.data
}

//Все машины из нашего Справочника
async function getAllVehicles() {
  let pageable = store.getters.getVehiclePageable;
  let sort = store.getters.getVehicleSort;
  const response = await httpClient.get(`/product/vehicles?page=${pageable.page}&size=${pageable.size}&sort=id,${sort}`);
  return response.data;
}

async function getArticlesByVehicles(id: string) {
  const response = await httpClient.get(`/product/vehicles/articles?id=${id}`);
  return response.data;
}

function articlesByVehicleToFile(id: any) {
  return `${axios.defaults.baseURL}/product/vehicles/articlesExport?id=${id}`
}

async function getByBrandIdAndModelName(brandId: string | number, modelName: string) {
  const response = await httpClient.get(`/product/vehicles?brandId=${brandId}&model=${encodeURIComponent(modelName)}`);
  return response.data;
}

function findByTecdocId(tecdocId: any) {
  return httpClient.get(`/product/vehicles?tecdocId=${tecdocId}`).then((response) => response.data);
}

function getVehicleImages(id: number | string) {
  return httpClient.get(`/product/vehicles/images?id=${id}`)
    .then(({ data }: any) => {
      return data.map((image: string) => {
        return `${process.env.VUE_APP_URL_FILES}${image.split('\\').join('/')}`
      })
    })
}

function addVehicleImage(formData: any) {
  return httpClient.post(`/product/vehicles/images`, formData)
    .then(({ data }: any) => {
      return `${process.env.VUE_APP_URL_FILES}${data.split('\\').join('/')}`
    })
}

function deleteVehicleImage(id: string | number, imageName: string, vehicleId: string | number) {
  return httpClient.delete(`/product/vehicles/images?id=${id}&imageName=${imageName}&vehicleId=${vehicleId}`)
}

export default {
  getVehicleImages,
  addVehicleImage,
  deleteVehicleImage,
  findByTecdocId,
  addVehicle,
  updateVehicle,
  findVehicleByModelIdAndBrandId,
  findById,
  findVehicleByModelId,
  getArticlesByVehicles,
  articlesByVehicleToFile,
  findVehicleByModelIds,
  getByBrandIdAndModelName
}
