export enum CardArticleActionTypes {
  SET_PARAMETER_VALUE = 'SET_PARAMETER_VALUE',
  SET_TCDC = 'SET_TCDC',
  FETCH_IMAGES = 'FETCH_IMAGES',
  DELETE_IMAGE = 'DELETE_IMAGE',
  ADD_IMAGE = 'ADD_IMAGE'
}

export enum LogisticParams {
  LENGTH = 'Длина',
  WIDTH = 'Ширина',
  HEIGHT = 'Высота',
  BULK = 'Объём',
  WEIGHT = 'Вес',
}

export enum LogisticParamsUnits {
  LENGTH = 'мм',
  WIDTH = 'мм',
  HEIGHT = 'мм',
  WEIGHT = 'кг',
  BULK = 'м3',
}
