import {GetterTree} from 'vuex';
import {RootState} from '@/store';
import {State} from './state';

export type Getters = {
  getParameters(state: State): Array<any>;
  getArticles(state: State): object;
  getPageable(state: State): object;
  getSort(state: State): string;
  getIsLoading(state: State): boolean,
  getGroupName(state: State): string
}

export const getters: GetterTree<State, RootState> & Getters = {
  getParameters: (state) => state.parameters,
  getArticles: (state) => state.articles,
  getPageable: (state) => state.pageable,
  getSort: (state) => state.sort,
  getIsLoading: (state) => state.isLoading,
  getGroupName: (state) => state.group,
  getSubGroupName: (state) => state.subGroup
};
