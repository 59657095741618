import {httpClient} from "@/httpClient";
import {
  AttachSystemPayload,
  DetachProductGroupPayload,
  System,
  SystemProductGroups
} from '@/models/SystemModel';

function allSystemsGroups(): Promise<SystemProductGroups[]> {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/systems/groups`).then(response => response.data)
}

function getSystems(): Promise<System[]> {
  return httpClient.get(`/product/${localStorage.getItem('cid')}/systems`).then((response) => response.data)
}

function attachSystem(payload: AttachSystemPayload): Promise<any> {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/systems/attach`, payload)
}

function detachSystem(payload: AttachSystemPayload) {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/systems/detach`, payload)
}

function updateSystem(payload: { name: string, id: number })  {
  return httpClient.put(`/product/${localStorage.getItem('cid')}/systems/${payload.id}`, {
    name: payload.name
  }).then(response => response.data)
}

function deleteSystem(systemId: number): Promise<void> {
  return httpClient.delete(`/product/${localStorage.getItem('cid')}/systems/${systemId}`)
}

function addSystem(payload: { name: string }): Promise<{ name: string, id: number }> {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/systems`, payload).then(response => response.data)
}

export default {
  addSystem,
  deleteSystem,
  updateSystem,
  detachSystem,
  attachSystem,
  allSystemsGroups,
  getSystems
}
