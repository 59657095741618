import {ActionTree, ActionContext} from 'vuex';
import {RootState, store} from '@/store';
import {State} from './state';
import {Mutations} from './mutations';
import {AuthenticationActionTypes} from './action-types';
import authentication from "@/services/authentication";
import {AuthenticationMutationTypes} from "@/store/modules/authentication/mutation-types";
import permissions from "@/services/permissions";
import users from "@/services/users";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [AuthenticationActionTypes.POST_SIGN_IN]({commit}: AugmentedActionContext, payload: object): Promise<any>

  [AuthenticationActionTypes.LOGOUT]({commit}: AugmentedActionContext, payload?: null): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [AuthenticationActionTypes.POST_SIGN_IN]({commit}, payload: object) {
    return new Promise((resolve, reject) => {
      authentication.createAuthenticationToken(payload).then(response => {
        resolve(response)
      }).catch(() => {
        reject()
      })
    })
  },
  [AuthenticationActionTypes.LOGOUT]({commit}, payload: null) {
    authentication.logout().then(_ => {
      store.commit(AuthenticationMutationTypes.SET_USER, payload);
    })
  },
  async [AuthenticationActionTypes.GET_PERMISSIONS_BY_ROLE]({commit}, roleId: any) {
    const response = await users.tokenInfo()
    store.commit('SET_PERMISSIONS', response.permissions)
  }
};
