import {store} from "@/store";

export const ConvertParameters = () => {
  let payload: { [index: string]: any } = {};
  let parameters = store.getters.getParameters;
  let dto: { [index: string]: any } = {
    productGroups: [], // Товарная группа - [ids]
    statuses: [], // Статус - [string]
    categories: [], // Категория - [string]
    articles: [],
    productSubGroups: [],
    productSubSubGroups: [],
    saleTypes: []
  };
  parameters.forEach((o: any) => {
    switch (o.type) {
      case 'PRODUCT_GROUP':
        dto.productGroups.push(o.value.id);
        break;
      case 'PRODUCT_SUB_GROUP':
        dto.productSubGroups.push(o.value.id);
        break;
      case 'PRODUCT_SUB_SUB_GROUP':
        dto.productSubSubGroups.push(o.value.id);
        break;
      case 'STATUS':
        dto.statuses.push(o.value);
        break;
      case 'CATEGORY':
        dto.categories.push(o.value);
        break;
      case 'ARTICLE':
        dto.articles.push(o.value)
        break;
      case 'CODETYPE':
        dto.saleTypes.push(o.value)
        break;
      default:
        break;
    }
  });
  Object.keys(dto).forEach(o => {
    if (typeof dto[o] === 'object' && Object.keys(dto[o]).length) {
      payload[o] = dto[o]
    } else if (typeof dto[o] !== 'object' && dto[o]) {
      payload[o] = dto[o]
    }
  });
  return payload
};
