import {GetterTree} from 'vuex';
import {RootState} from '@/store';
import {State} from './state';
import { devices } from '@/components/types/deviceUtils';

export type Getters = {
  isMobile(state: State): boolean
  isDesktop(state: State): boolean
  isTablet(state: State): boolean
}

export const getters: GetterTree<State, RootState> & Getters = {
  isMobile: (state: State) => state.currentDevice === devices.MOBILE,
  isDesktop: (state: State) => state.currentDevice === devices.DESKTOP,
  isTablet: (state: State) => state.currentDevice === devices.TABLET,
};
