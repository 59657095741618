import {MutationTree} from 'vuex';
import {State} from './state';
import {VehicleMutationTypes} from './mutation-types';
import {VehicleActionTypes} from "./action-types";
import {store} from "@/store";
import {ConvertParameters} from "@/components/converters/articles";

export type Mutations<S = State> = {
  [VehicleMutationTypes.SET_VEHICLES](state: S, payload: any): void;
  [VehicleMutationTypes.SET_VEHICLES_BRANDS](state: S, payload: any): void;
  [VehicleMutationTypes.GET_VEHICLES](state: S, payload?: null): void;
  [VehicleMutationTypes.GET_VEHICLES_BRANDS](state: S, payload?: null): void;
  [VehicleMutationTypes.GET_VEHICLES_MODELS_BY_BRAND](state: S, payload: any): void;
  [VehicleMutationTypes.SEARCH_VEHICLES_BY_PARAMETERS](state: S, payload?: null): void;
  [VehicleMutationTypes.NEXT_PAGE_VEHICLES](state: S, payload?: null): void;
  [VehicleMutationTypes.PREVIOUS_PAGE_VEHICLES](state: S, payload?: null): void;
  [VehicleMutationTypes.SET_SORT](state: S, payload: string): void;
  [VehicleMutationTypes.SET_SIZE](state: S, size: any): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [VehicleMutationTypes.SET_VEHICLES](state: State, payload: any) {
    state.vehicles = payload
  },
  [VehicleMutationTypes.SET_VEHICLES_BRANDS](state: State, payload: any) {
    state.vehiclesBrands = payload
  },
  async [VehicleMutationTypes.GET_VEHICLES](state: State) {
    state.isLoading = true;
    state.pageable.page = 0;
    try {
      let result = null;
      let parameters = ConvertParameters();
      if (Object.keys(parameters).length) {
        result = await store.dispatch(VehicleActionTypes.SEARCH_VEHICLES_BY_PARAMETERS, parameters)
      } else {
        result = await store.dispatch(VehicleActionTypes.GET_VEHICLES)
      }
      store.commit(VehicleMutationTypes.SET_VEHICLES, result);
      state.isLoading = false
    } catch (error) {
      state.isLoading = false
    }
  },
  async [VehicleMutationTypes.GET_VEHICLES_BRANDS](state: State) {
    state.isLoading = true;
    state.pageable.page = 0;
    try {
      let result = null;
      result = await store.dispatch(VehicleActionTypes.GET_VEHICLES_BRANDS)
      store.commit(VehicleMutationTypes.SET_VEHICLES_BRANDS, result);
      state.isLoading = false
    } catch (error) {
      state.isLoading = false
    }
  },
  async [VehicleMutationTypes.GET_VEHICLES_MODELS_BY_BRAND](state: State, payload: any) {
    state.isLoading = true;
    state.pageable.page = 0;
    try {
      let result = null;
      result = await store.dispatch(VehicleActionTypes.GET_VEHICLES_MODELS_BY_BRAND, payload)
      // store.commit(VehicleMutationTypes.SET_VEHICLES_BRANDS, result);
      state.isLoading = false
    } catch (error) {
      state.isLoading = false
    }
  },
  async [VehicleMutationTypes.SEARCH_VEHICLES_BY_PARAMETERS](state: State) {
    state.isLoading = true;
    state.pageable.page = 0;
    try {
      let result = null;
      result = await store.dispatch(VehicleActionTypes.SEARCH_VEHICLES_BY_PARAMETERS)
      store.commit(VehicleMutationTypes.SET_VEHICLES_BRANDS, result);
      state.isLoading = false
    } catch (error) {
      state.isLoading = false
    }
  },
  async [VehicleMutationTypes.NEXT_PAGE_VEHICLES](state: State) {
    state.isLoading = true;
    state.pageable.page = state.pageable.page + 1;
    try {
      let result = null;
      let parameters = ConvertParameters();
      if (Object.keys(parameters).length) {
        result = await store.dispatch(VehicleActionTypes.SEARCH_VEHICLES_BY_PARAMETERS, parameters)
      } else {
        result = await store.dispatch(VehicleActionTypes.GET_VEHICLES)
      }
      store.commit(VehicleMutationTypes.SET_VEHICLES, result);
      state.isLoading = false
    } catch (error) {
      state.pageable.page = --state.pageable.page;
      state.isLoading = false
    }
  },
  async [VehicleMutationTypes.PREVIOUS_PAGE_VEHICLES](state: State) {
    state.isLoading = true;
    state.pageable.page = state.pageable.page - 1;
    try {
      let result = null;
      let parameters = ConvertParameters();
      if (Object.keys(parameters).length) {
        result = await store.dispatch(VehicleActionTypes.SEARCH_VEHICLES_BY_PARAMETERS, parameters)
      } else {
        result = await store.dispatch(VehicleActionTypes.GET_VEHICLES)
      }
      store.commit(VehicleMutationTypes.SET_VEHICLES, result);
      state.isLoading = false
    } catch (error) {
      state.pageable.page = ++state.pageable.page;
      state.isLoading = false
    }
  },
  [VehicleMutationTypes.SET_SORT](state: State, payload: string) {
    state.sort = payload;
    store.commit(VehicleMutationTypes.GET_VEHICLES)
  },
  [VehicleMutationTypes.SET_VEHICLES_BLOCKED](state: State, payload: boolean) {
    state.vehicleIsBlocked = payload;
  },
  [VehicleMutationTypes.SET_SIZE](state: State, size: any) {
    state.pageable.size = size
  },
  [VehicleMutationTypes.SET_OPTIONS_FILTER](state: State, { conditionOption, filterName }) {
    // @ts-ignore
    const options = state.vehicles.map((vehicle: any) => conditionOption(vehicle));
    // @ts-ignore
    state.filters[filterName].options = [...new Set(options)]
  },
  [VehicleMutationTypes.SET_FILTER](state: State, dataFilters: Array<{filterName: string, item: any, conditionFilter: any, conditionOption: any}>) {
    dataFilters.map(({ filterName, item, conditionOption, conditionFilter }: any) => {
      // @ts-ignore
      state.filters[filterName] = {
        data: item,
        ...(conditionFilter && { conditionFilter }),
        ...(conditionOption
            ? { conditionOption }
            // @ts-ignore
            : { conditionOption: state.filters[filterName].conditionOption }
        ),
        // @ts-ignore
        options: conditionOption
          ? [...new Set(state.vehicles.map((vehicle: any) => conditionOption(vehicle)))]
          // @ts-ignore
          : state.filters[filterName].options
      }
    })
  },
};
