import {GetterTree} from 'vuex';
import {RootState} from '@/store';
import {State} from './state';
import { SystemProductGroups } from '@/models/SystemModel';

export type Getters = {
  getSystemsProductGroups(state: State): Array<SystemProductGroups>,
  getErrorAttachSystem(state: State): string | null,
}

export const getters: GetterTree<State, RootState> & Getters = {
  getSystemsProductGroups: (state) => state.systemsProductGroups,
  getErrorAttachSystem: (state) => state.attachSystemError,
};
