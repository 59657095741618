import {httpClient} from "@/httpClient";

async function addVehicleModel(payload: object) {
  const response = await httpClient.post(`/product/vehicles/models`, payload);
  return response.data;
}

async function updateVehicleModel(modelId: string, payload: object) {
  const response = await httpClient.put(`/product/vehicles/models/${modelId}`, payload);
  return response.data;
}

async function getModelById(modelId: any) {
  const response = await httpClient.get(`/product/vehicles/models/${modelId}`);
  return response.data;
}

async function getModelsByIds(modelsIds: any) {
  return await httpClient.post(`product/vehicles/modelIds`, modelsIds.split(',')).then((response) => response.data)
}

//Разблокировать модель
async function setUnblockedModel(modelId: number) {
  const response = await httpClient.patch(`/product/vehicles/models/${modelId}/unblock`);
  return response.data;
}

//Заблокировать модель
async function setBlockedModel(modelId: number) {
  const response = await httpClient.patch(`/product/vehicles/models/${modelId}/block`);
  return response.data;
}

//Модели машин по айди бренда
async function getModelsByBrand(brandId: string, isBlocked?: any) {
  const response = await httpClient.get(`/product/vehicles/models?${isBlocked === 'ALL' ? '' : isBlocked ? 'blocked=BLOCKED&' : 'blocked=UNBLOCKED&'}brandId=${brandId}`);
  return response.data;
}

//Модели машин(очень большой запрос)
async function getAllModels() {
  const response = await httpClient.get(`/product/vehicles/models`);
  return response.data;
}

//Модели машин по айди бренда и статусу блокировки
async function getModelsByBrandIdAndBlocking(brandId: string, isBlocked?: any, type?: any) {
  const response = await httpClient.get(`/product/vehicles/models?${isBlocked === 'ALL' ? '' : isBlocked ? 'blocked=BLOCKED&' : 'blocked=UNBLOCKED&'}brandId=${brandId}${type ? `&type=${type}` : ''}`);
  return response.data;
}

//Модели машин по айди бренда, статусу блокировки и категории
async function getModelsByBrandIdAndTypeAndBlocking(brandId: string, isBlocked?: any, type?: string) {
  const response = await httpClient.get(`/product/vehicles/models?${isBlocked === 'ALL' ? '' : isBlocked ? 'blocking=BLOCKED&' : 'blocking=UNBLOCKED&'}brandId=${brandId}${type && type.length ? `&type=${type}` : ''}`);
  return response.data;
}

async function getModelsByBrandId(brandId: any) {
  return await httpClient.get(`/product/vehicles/models?brandId=${brandId}`).then(response => response.data)
}

export default {
  addVehicleModel,
  updateVehicleModel,
  getModelById,
  setUnblockedModel,
  setBlockedModel,
  getModelsByBrand,
  // getAllModels,
  getModelsByBrandIdAndBlocking,
  getModelsByBrandIdAndTypeAndBlocking,
  getModelsByBrandId,
  getModelsByIds
}
