export enum FilterNames {
  FUEL = 'FUEL',
  ENGINE = 'ENGINE',
  BRAND = 'BRAND',
  MODEL = 'MODEL',
}

export type TypeFilterNames = keyof typeof FilterNames;

export type FilterItems = {
  [K in FilterNames]: FilterItem
}

export interface FilterItem {
  data: any,
  options: Array<any>,
  conditionFilter: Function | null,
  conditionOption: Function | null
}

export type State = {
  parameters: Array<any> // Параметры поиска
  vehicles: any // Машины
  vehiclesBrands: object // Марки машин
  vehiclesModels: object // Модели машин
  pageable: { page: number, size: number } // Текущая страница и количество отображения артикулов
  sort: string // Сортировка (asc, desc)
  isLoading: boolean,
  vehicleIsBlocked: boolean,
  vehicleTypes: object[],
  vehicleCategories: object[],
  filters: FilterItems
}

export const state: State = {
  parameters: [],
  vehicles: {},
  vehiclesBrands: {},
  vehiclesModels: {},
  pageable: {
    page: 0,
    size: 20
  },
  sort: 'asc',
  isLoading: false,
  vehicleIsBlocked: false,
  vehicleTypes: [
    {key: 'moto', value: 'Мотоциклы'},
    {key: 'light', value: 'Легковые'},
    {key: 'commerce', value: 'Лёгкие коммерческие'},
    {key: 'heavy', value: 'Грузовые и автобусы'},
    {key: 'agro', value: 'Тракторы и спецтехника'},
    {key: 'all_categories', value: 'Все категории'}
  ],
  vehicleCategories: [
    {key: 'MOTORBIKE', value: 'Мотоциклы'},
    {key: 'PASSENGER', value: 'Легковые'},
    {key: 'COMMERCIAL', value: 'Лёгкие коммерческие'},
    {key: 'TRUCK_AND_BUS', value: 'Грузовые и автобусы'},
    {key: 'TRACTOR_AND_SPECIAL', value: 'Тракторы и спецтехника'},
  ],
  filters: {
    BRAND: {
      data: null,
      options: [],
      conditionFilter: null,
      conditionOption: null,
    },
    MODEL: {
      data: null,
      options: [],
      conditionFilter: null,
      conditionOption: null,
    },
    FUEL: {
      data: null,
      options: [],
      conditionFilter: null,
      conditionOption: null,
    },
    ENGINE: {
      data: null,
      options: [],
      conditionFilter: null,
      conditionOption: null,
    },
  }
};
