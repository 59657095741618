import {MutationTree} from 'vuex';
import {State} from './state';
import {CardArticleMutationTypes} from './mutation-types';
import {CardArticleActionTypes} from './action-types';
import {store} from "@/store";
import { IDataSlider, DataSliderKeys } from '@/models/VehicleModel';

export type Mutations<S = State> = {
  [CardArticleMutationTypes.SET_ARTICLE](state: S, payload: any): void
  [CardArticleMutationTypes.SET_PARAMETER_VALUE](state: S, payload: string): void
  [CardArticleMutationTypes.SET_ACTIVE_TAB](state: S, payload: any): void
  [CardArticleMutationTypes.CREATE_PARAMETER_VALUE](state: S, payload: any): void
  [CardArticleMutationTypes.UPDATE_PARAMETER_VALUE](state: S, payload: any): void
  [CardArticleMutationTypes.DELETE_PARAMETER_VALUE](state: S, payload: any): void
  [CardArticleMutationTypes.DELETE_ALL_PARAMETER_VALUE](state: S, payload: any): void
  [CardArticleMutationTypes.DELETE_UNCONFIRMED_VALUE](state: S, payload: any): void
  [CardArticleMutationTypes.SET_TCDC](state: S, payload: any): void
  [CardArticleMutationTypes.CLEAR_STATE](state: S, payload?: null): void
  [CardArticleMutationTypes.CHANGE_VIEW](state: S, payload: any): void
  [CardArticleMutationTypes.UPDATE_NOTE](state: S, textNote: string): void
  [CardArticleMutationTypes.SET_DATA_SLIDER](state: S, payload: Array<object>): void
  [CardArticleMutationTypes.UPDATE_SLIDER_IMAGES](state: S, data: {key: string, articleId: string, file: string}): void
  [CardArticleMutationTypes.ADD_IMAGE_SLIDER](state: S, data: { key: string, value: string }): void
}

export const mutations: MutationTree<State> & Mutations = {
  [CardArticleMutationTypes.SET_ARTICLE](state: State, payload: any) {
    state.article = payload;
    state.activeTab = state.sideBar[0];
    state.params.forEach(o => {
      o.confirmed = [];
      o.unconfirmed = [];
      o.view = 'CONFIRMED'
    });
    store.commit(CardArticleMutationTypes.SET_PARAMETER_VALUE, payload)
  },
  async [CardArticleMutationTypes.SET_PARAMETER_VALUE](state: State, article: any) {
    for (let o of state.params) {
      try {
        state.loading = true
        let payload = {tab: o.key, articleId: article.articleId};
        o.confirmed = await store.dispatch(CardArticleActionTypes.SET_PARAMETER_VALUE, payload);

        if (o && o.duplicateConfirmed) {
          o.duplicateConfirmed = o.confirmed
        }
      } catch (error) {
        throw error
      }
    }
    state.loading = false
  },
  [CardArticleMutationTypes.SET_ACTIVE_TAB](state: State, payload: any) {
    state.activeTab = payload
  },
  [CardArticleMutationTypes.CHANGE_VIEW](state: State, payload: any) {
    let parameter = state.params.find(o => o.key === payload.key);
    parameter.view = payload.value
  },
  [CardArticleMutationTypes.CREATE_PARAMETER_VALUE](state: State, payload: any) {
    let parameter = state.params.find(o => o.key === payload.key);
    switch (payload.key) {
      case 'APPLICABILITY':
      case 'CROSS':
        parameter.confirmed = payload.value;
        parameter.duplicateConfirmed = payload.value;
        break;
      case 'ANALOG':
        let used = {} as any;
        let array = parameter.confirmed.concat(payload.value);
        parameter.confirmed = array.filter((o: any) => o.id in used ? 0 : (used[o.id] = 1));
        break;
      case 'TREE':
        if (payload && payload.isSuitableTree) {
          parameter.suitableTree = [...payload.value]
        } else {
          parameter.confirmed = parameter.confirmed.concat(payload.value);
        }
        break;
      case 'PAIR':
      case 'PARAMS':
        parameter.confirmed.push(payload.value);
        break;
      default:
        break
    }
  },
  [CardArticleMutationTypes.UPDATE_PARAMETER_VALUE](state: State, payload: any) {
    let index = undefined;
    let parameter = state.params.find(o => o.key === payload.key);
    switch (payload.key) {
      case 'ANALOG':
        payload.value.forEach((o: any) => {
          let found = parameter.confirmed.find((i: any) => i.id === o.id);
          found.articleComment = o.articleComment
        });
        break;
      case 'CROSS':
        parameter.confirmed.find((o: any) => o.id === payload.value).focus = !parameter.confirmed.find((o: any) => o.id === payload.value).focus;
        break;
      case 'PAIR':
        index = parameter.confirmed.findIndex((o: any) => o.lrId === payload.value.lrId);
        parameter.confirmed.splice(index, 1, payload.value);
        break;
      case 'TREE':
        payload.value.forEach((o: any) => {
          let found = parameter.confirmed.find((i: any) => i.treeId === o.treeId);
          found.branches = found.branches.concat(o.branches)
        });
        break;
      case 'PARAMS':
        index = parameter.confirmed.findIndex((o: any) => o.id === payload.value.id);
        parameter.confirmed.splice(index, 1, payload.value);
        break;
      default:
        break
    }
  },
  [CardArticleMutationTypes.DELETE_PARAMETER_VALUE](state: State, payload: any) {
    let index = undefined;
    let indexConfirmedVehicle = undefined;

    let parameter = state.params.find(o => o.key === payload.key);
    switch (payload.key) {
      case 'CROSS':
      case 'PARAMS':
      case 'APPLICABILITY':
      case 'ANALOG':
        if (parameter && parameter.confirmed) {
          index = parameter.confirmed.findIndex((o: any) => o.id === payload.value)
          parameter.confirmed.splice(index, 1)

          if (parameter && parameter.duplicateConfirmed) {
            indexConfirmedVehicle = parameter.duplicateConfirmed.findIndex((o: any) => o.id === payload.value);
            if (indexConfirmedVehicle >= 0) {
              parameter.duplicateConfirmed.splice(indexConfirmedVehicle, 1);
            }
          }
        }
        break;
      case 'PAIR':
        index = parameter.confirmed.findIndex((o: any) => o.lrId === payload.value);
        parameter.confirmed.splice(index, 1);
        break;
      case 'TREE':
        if (payload.value.nodeId) {
          let tree = parameter.confirmed.find((o: any) => o.treeId === payload.value.treeId);
          index = tree.branches.findIndex((o: any) => o.nodeId === payload.value.nodeId);
          tree.branches.splice(index, 1)
        } else {
          index = parameter.confirmed.findIndex((o: any) => o.treeId === payload.value.treeId);
          parameter.confirmed.splice(index, 1)
        }
        break;
      default:
        break
    }
  },[CardArticleMutationTypes.DELETE_ALL_PARAMETER_VALUE](state: State, payload: any) {
    let parameter = state.params.find(o => o.key === payload.key);
    switch (payload.key) {
      case 'CROSS':
      case 'PARAMS':
      case 'APPLICABILITY':
      case 'ANALOG':
      case 'PAIR':
        parameter.confirmed = [];
        parameter.duplicateConfirmed = [];
        break;
      case 'TREE':
        break;
      default:
        break
    }
  },
  [CardArticleMutationTypes.DELETE_UNCONFIRMED_VALUE](state: State, payload: any) {
    let index = undefined;
    let indexDuplicate = undefined;
    let parameter = state.params.find(o => o.key === payload.key);
    switch (payload.key) {
      case 'CROSS':
        index = parameter.unconfirmed.findIndex((o: any) => o.brand === payload.value.brand && o.brandId === payload.value.brandId && o.oeArticle === payload.value.oeArticle);
        parameter.unconfirmed.splice(index, 1);
        break;
      case 'APPLICABILITY':
        payload.value.forEach((o: any) => {
          index = parameter.unconfirmed.findIndex((i: any) => i.carId === o);
          if (index >= 0) {
            parameter.unconfirmed.splice(index, 1);
          }

          // indexDuplicate = parameter.duplicateUnconfirmed.findIndex((i: any) => i.carId === o)
          // if (indexDuplicate >= 0) {
          //   parameter.duplicateUnconfirmed.splice(indexDuplicate, 1)
          // }
        });
        break;
      default:
        break
    }
  },
  [CardArticleMutationTypes.SET_TCDC](state: State, payload: any) {
    // Кроссировка
    if (!!payload.oeCrosses.length) {
      let parameter = state.params.find(o => o.key === 'CROSS');
      payload.oeCrosses = payload.oeCrosses.filter((o: any) => !parameter.confirmed.some((i: any) => i.brand === o.brand && i.brandId === o.brandId && i.brandArt.replace(/[^a-zA-Z0-9]/g, '') === o.oeArticle.replace(/[^a-zA-Z0-9]/g, '')));
      payload.oeCrosses = payload.oeCrosses.filter((o: any) => !parameter.unconfirmed.some((i: any) => i.brand === o.brand && i.brandId === o.brandId && i.oeArticle.replace(/[^a-zA-Z0-9]/g, '') === o.oeArticle.replace(/[^a-zA-Z0-9]/g, '')));
      parameter.unconfirmed = parameter.unconfirmed.concat(payload.oeCrosses)
    }
    // Параметры
    if (!!payload.params.length) {
      let parameter = state.params.find(o => o.key === 'PARAMS');
      payload.params.forEach((o: any) => {
        o.cross = `${payload.brand} ${payload.brandArt}`
      });
      parameter.unconfirmed = parameter.unconfirmed.concat(payload.params.filter((o: any) => !parameter.unconfirmed.some((i: any) => i.criteriaId === o.criteriaId)))
    }
    // Применяемость
    if (payload?.vehicles?.length) {
      const parameter = state.params.find(o => o.key === 'APPLICABILITY');

      const filteredItems = payload.vehicles.filter((vehicle: any) => {
        const isFindInConfirmedParameters = !parameter.confirmed.some((confirmedParameter: any) => {
          return confirmedParameter.tecdocVehicleId === vehicle.carId
        })

        const isFindInUnconfirmedParameters = !parameter.unconfirmed.some((unconfirmedParameter: any) => {
          return unconfirmedParameter.carId === vehicle.carId
        })

        return isFindInConfirmedParameters && isFindInUnconfirmedParameters
      })

      parameter.unconfirmed = parameter.unconfirmed.concat(filteredItems)
      parameter.duplicateUnconfirmed = parameter.unconfirmed
    } else {
      let applicability = state.params.find(o => o.key === 'APPLICABILITY');
      applicability.unconfirmed.splice(0, applicability.unconfirmed.length)
      applicability.duplicateUnconfirmed = []
    }
  },
  [CardArticleMutationTypes.CLEAR_STATE](state: State) {
    state.article = null;
    state.activeTab = state.sideBar[0];
    state.params.forEach(o => {
      o.confirmed = [];
      o.unconfirmed = [];
      o.view = 'CONFIRMED'
    })
  },
  [CardArticleMutationTypes.UPDATE_NOTE](state: State, text: string) {
    if (state.article) {
      state.article.note = text
    }
  },
  [CardArticleMutationTypes.SET_DATA_SLIDER](state: State, payload: Array<IDataSlider>) {
    state.dataSlider = [...payload]
  },
  [CardArticleMutationTypes.UPDATE_SLIDER_IMAGES](state: State, data: {key: DataSliderKeys, articleId: string, file: string}) {
    const indexChooseDataSlider = state.dataSlider.findIndex((view: IDataSlider) => {
      return view.key === data.key
    })

    const newImages = state.dataSlider[indexChooseDataSlider].value
      .filter((value) => {
        return value !== data.file
      })

    state.dataSlider[indexChooseDataSlider] = {
      ...state.dataSlider[indexChooseDataSlider],
      value: newImages
    }
  },
  [CardArticleMutationTypes.ADD_IMAGE_SLIDER](state: State, data: { key: string, value: string }) {
    const indexChooseDataSlider = state.dataSlider.findIndex((view: IDataSlider) => {
      return view.key === data.key
    })

    state.dataSlider[indexChooseDataSlider].value.push(data.value)
  }
};
