import axios from 'axios';
import { AuthenticationActionTypes } from '@/store/modules/authentication/action-types';
import { store } from '@/store';
import { EnvKey } from '@/models/GlobalModel';

// const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://integra.fenox.com/backend' : 'http://37.17.69.14:8080/backend';
let BASE_URL = 'http://localhost:8081'
switch(process.env.NODE_ENV as EnvKey){
  case EnvKey.DEVELOPMENT:
    BASE_URL = 'http://87.252.254.135:8080/backend';
    // BASE_URL = 'https://integra.fenox.com/backend';
    break;
  case EnvKey.DEMO:
    BASE_URL = 'http://87.252.254.135:8081/backend';
    // BASE_URL = 'https://integra.fenox.com/backend';
    break;
  case EnvKey.PRODUCTION:
    BASE_URL = 'https://integra.fenox.com/backend';
    break;
  default:
    BASE_URL = 'http://localhost:8081';
    break;
}

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

if (localStorage.getItem('eventAppToken')) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('eventAppToken');
}

const httpClient = axios.create({baseURL: BASE_URL});

axios.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(error)
);

axios.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(error)
);

httpClient.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(error)
);

httpClient.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(error)
);

const responseHandler = (response: any) => {
  const token = response.headers.authorization;
  if (token) localStorage.setItem('eventAppToken', token);
  return response
};

const requestHandler = (request: any) => {
  if (localStorage.getItem('eventAppToken')) {
    request.headers.Authorization = 'Bearer ' + localStorage.getItem('eventAppToken')
  }
  return request
};

const errorHandler = (error: any) => {
  if (error.message === 'Network Error') {
    // Реальные ошибки скрыты от нас браузером (сетевая ошибка)
    return Promise.reject({...error})
  } else {
    // Токен
    if (error.response.status === 401) {
      store.dispatch(AuthenticationActionTypes.LOGOUT, null)
    }
    return Promise.reject({...error.response})
  }
};

export {
  httpClient
}
