import {MutationTree} from 'vuex';
import {State} from './state';
import {AuthenticationMutationTypes} from './mutation-types';
import {AuthenticationActionTypes} from "@/store/modules/authentication/action-types";
import {store} from "@/store";
import router from "@/router";
import {Roles} from "@/components/types/enums";

export type Mutations<S = State> = {
  [AuthenticationMutationTypes.SET_USER](state: S, payload: any): void
  [AuthenticationMutationTypes.POST_SIGN_IN](state: S, payload: object): void
  [AuthenticationMutationTypes.CLOSE_ERROR](state: S, payload: boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
  async [AuthenticationMutationTypes.SET_USER](state: State, payload: any) {
    state.loggedUser = payload;

    if (payload) {
      localStorage.setItem('loggedUser', JSON.stringify(payload));
      localStorage.setItem('eventAppToken', payload.token);
      localStorage.setItem('cid', payload.cid)
    } else {
      localStorage.removeItem('loggedUser');
      localStorage.removeItem('eventAppToken');
      localStorage.removeItem('cid');
      router.push({name: 'login'})
    }

    if(payload && payload.role) {
      await store.dispatch('GET_PERMISSIONS_BY_ROLE', Roles[payload.role])
    }
  },
  async [AuthenticationMutationTypes.POST_SIGN_IN](state: State, payload: object) {
    localStorage.removeItem('loggedUser');
    localStorage.removeItem('eventAppToken');
    localStorage.removeItem('cid');
    if (state.isError) state.isError = false;
    try {
      let result = await store.dispatch(AuthenticationActionTypes.POST_SIGN_IN, payload);
      store.commit(AuthenticationMutationTypes.SET_USER, result);
      router.push({name: 'search'})
    } catch (error) {
      state.isError = true;
      throw error
    }
  },
  [AuthenticationMutationTypes.CLOSE_ERROR](state: State, payload: boolean) {
    state.isError = payload
  },
  ['SET_PERMISSIONS'](state: State, payload: any) {
    Object.keys(state.permissions)
      .map((permission: any) => {
        // @ts-ignore
        state.permissions[permission][state?.loggedUser?.role] = payload.find((el: any) => el.key === permission)
          ? true
          : false
    })

  }
};
