import {GetterTree} from 'vuex';
import {RootState} from '@/store';
import {State} from './state';

export type Getters = {
  getArticleCard(state: State): any;
  getSideBarCard(state: State): Array<any>;
  getParamsCard(state: State): Array<any>;
  getActiveTabCard(state: State): any;
  getParamApplicability(state: State): any;
  articleDataView(state: State): Array<any>;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getArticleCard: (state) => state.article,
  getSideBarCard: (state) => state.sideBar,
  getParamsCard: (state) => state.params,
  getActiveTabCard: (state) => state.activeTab,
  getParamApplicability: (state) => state.params.find((param: any) => param.key === 'APPLICABILITY'),
  articleDataView: (state) => state.dataSlider
};
