import {httpClient} from "@/httpClient";

function createAuthenticationToken(payload: object) {
  return httpClient.post('/auth/auth', payload).then(response => response.data)
}

function logout() {
  return httpClient.post('/auth/logout').then(response => response.data)
}

export function GetUserName(id: number) {
  if (id === 13) {
    return 'Admin Admin'
  } else {
    return id
  }
}

export default {
  createAuthenticationToken,
  logout
}
