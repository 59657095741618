import {MutationTree} from 'vuex';
import {State} from './state';

export type Mutations<S = State> = {
  setImage(state: S, image: string): void;
  deleteImage(state: S, imageName: any): void;
  setImages(state: S, images: Array<string>): void;
}

export const mutations: MutationTree<State> & Mutations = {
  setImage(state: State, image: string) {
    state.images.push(image)
  },
  deleteImage(state: State, imageName: string) {
    state.images = [...state.images.filter((image: string) => image !== imageName)]
  },
  setImages(state: State, images: Array<string>) {
    state.images = [...images]
  }
};
