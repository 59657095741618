import { IDataViewArticleCard } from '@/models/VehicleModel';

export type State = {
  loading: boolean // Загрузка применямости
  article: any // Текущий артикул
  activeTab: any // Текущая активная вкладка SideBar
  sideBar: Array<any> // Боковое меню
  params: Array<any> // Параметры артикула
  dataSlider: IDataViewArticleCard[]
}

export const state: State = {
  loading: false,
  article: null,
  activeTab: null,
  sideBar: [
    {key: 'BASIC', name: 'Основная информация'},
    {key: 'CROSS', name: 'Кроссировка'},
    {key: 'PARAMS', name: 'Параметры и описание'},
    {key: 'LOGISTIC', name: 'Логистические параметры'},
    {key: 'TREE', name: 'Входит в - Состоит из'},
    {key: 'APPLICABILITY', name: 'Применяемость'},
    {key: 'OZON', name: 'OZON'},
    {key: 'HISTORY', name: 'История'},
    {key: 'DOCUMENTS', name: 'Документы и ссылки'},
    {key: 'ATTRIBUTES', name: 'Требования к изделию'},
    {key: 'CERTIFICATE', name: 'Сертификат'},
  ],
  dataSlider: [
    {name: 'Фото', value: [], key: 'IMAGES' },
    {name: 'Чертежи', value: [], key: 'DRAW' }
  ],
  params: [
    {
      key: 'CROSS',
      name: 'Кроссировка',
      desc: 'ОЕ и СМ кроссы, определённые менеджером',
      confirmed: [],
      unconfirmed: [],
      view: 'CONFIRMED'
    },
    {
      key: 'ANALOG',
      name: 'Аналоги',
      desc: 'Артикулы, отличающиеся только физическими свойствами',
      confirmed: [],
      unconfirmed: [],
      view: 'CONFIRMED'
    },
    {
      key: 'PAIR',
      name: 'Парность',
      desc: 'Артикулы, отличающиеся стороной установки',
      confirmed: [],
      unconfirmed: [],
      view: 'CONFIRMED'
    },
    {
      key: 'TREE',
      name: 'Входит в - Состоит из',
      desc: 'Дерево составного артикула',
      suitableTree: [],
      confirmed: [],
      unconfirmed: [],
      view: 'CONFIRMED'
    },
    {
      key: 'PARAMS',
      name: 'Параметры',
      desc: 'Геометрические и технические параметры',
      confirmed: [],
      unconfirmed: [],
      view: 'CONFIRMED'
    },
    {
      key: 'APPLICABILITY',
      name: 'Применяемость',
      duplicateConfirmed: [],
      duplicateUnconfirmed: [],
      confirmed: [],
      unconfirmed: [],
      view: 'CONFIRMED'
    }
  ],
};
