export enum CardArticleMutationTypes {
  SET_ARTICLE = 'SET_ARTICLE',
  SET_PARAMETER_VALUE = 'SET_PARAMETER_VALUE',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  CREATE_PARAMETER_VALUE = 'CREATE_PARAMETER_VALUE',
  UPDATE_PARAMETER_VALUE = 'UPDATE_PARAMETER_VALUE',
  DELETE_PARAMETER_VALUE = 'DELETE_PARAMETER_VALUE',
  DELETE_ALL_PARAMETER_VALUE = 'DELETE_ALL_PARAMETER_VALUE',
  DELETE_UNCONFIRMED_VALUE = 'DELETE_UNCONFIRMED_VALUE',
  SET_TCDC = 'SET_TCDC',
  CLEAR_STATE = 'CLEAR_STATE',
  CHANGE_VIEW = 'CHANGE_VIEW',
  UPDATE_NOTE = 'UPDATE_NOTE',
  SET_DATA_SLIDER = 'SET_DATA_SLIDER',
  UPDATE_SLIDER_IMAGES = 'UPDATE_SLIDER_IMAGES',
  ADD_IMAGE_SLIDER = 'ADD_IMAGE_SLIDER'
}
