import {GetterTree} from 'vuex';
import {RootState} from '@/store';
import {State} from './state';

export type Getters = {
  dataView(state: State): any;
}

export const getters: GetterTree<State, RootState> & Getters = {
  dataView: (state) => ({
    name: state.view,
    value: state.images
  }),
};
