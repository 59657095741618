import {ActionContext, ActionTree} from 'vuex';
import {RootState} from '@/store';
import {State} from './state';
import {Mutations} from './mutations';
import {VehicleActionTypes} from './action-types';
import vehicles from "@/services/vehicles";
import vehicleModels from "@/services/vehicleModels";
import vehicleBrands from "@/services/vehicleBrands";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [VehicleActionTypes.SEARCH_VEHICLES_BY_PARAMETERS]({commit}: AugmentedActionContext, payload: object): Promise<any>;
  [VehicleActionTypes.GET_VEHICLES]({commit}: AugmentedActionContext): Promise<any>;
  [VehicleActionTypes.GET_VEHICLES_BRANDS]({commit}: AugmentedActionContext): Promise<any>;
  [VehicleActionTypes.GET_VEHICLES_MODELS_BY_BRAND]({commit}: AugmentedActionContext, payload: object): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [VehicleActionTypes.SEARCH_VEHICLES_BY_PARAMETERS]({commit}, payload: object) {
    return new Promise((resolve, reject) => {
      vehicles.findVehicleByModelIdAndBrandId('460', '').then(response => {
        resolve(response)
      }).catch(() => {
        reject()
      })
    })
  },
  async [VehicleActionTypes.GET_VEHICLES]() {
    // return new Promise((resolve, reject) => {
    //   vehicles.getAllVehicles().then(response => {
    //     resolve(response)
    //   }).catch(() => {
    //     reject()
    //   })
    // })
  },
  async [VehicleActionTypes.GET_VEHICLES_BRANDS]() {
    return new Promise((resolve, reject) => {
      vehicleBrands.getAllVehiclesBrands().then(response => {
        resolve(response)
      }).catch(() => {
        reject()
      })
    })
  },
  async [VehicleActionTypes.GET_VEHICLES_MODELS_BY_BRAND]({commit}, payload: any) {
    return new Promise((resolve, reject) => {
      vehicleModels.getModelsByBrandIdAndBlocking(payload.id).then(response => {
        resolve(response)
      }).catch(() => {
        reject()
      })
    })
  },
};
