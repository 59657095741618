import {createStore, createLogger} from 'vuex';
import {
  store as authentication,
  AuthenticationStore,
  State as AuthenticationState
} from '@/store/modules/authentication';
import {
  store as articles,
  ArticleStore,
  State as ArticleState
} from '@/store/modules/articles';
import {
  store as vehicles,
  VehicleStore,
  State as VehicleState
} from '@/store/modules/vehicles';
import {
  store as card,
  CardArticleStore,
  State as CardArticleState
} from '@/store/modules/article-card';
import {
  store as sheet,
  DevelopmentSheetStore,
  State as DevelopmentSheetState
} from '@/store/modules/development-sheet';
import {
  store as messageModal,
  ModalStore,
  State as ModalState
} from '@/store/modules/message-modal';
import {
  store as device,
  DeviceStore,
  State as DeviceState
} from '@/store/modules/device';
import {
  store as certificates,
  CertificatesStore,
  State as CertificatesState
} from '@/store/modules/certificates';
import {
  store as systemProductGroups,
  SystemProductGroupsStore,
  State as SystemProductGroupsState
} from '@/store/modules/system-product-groups';
import {
  store as vehicleCard,
  VehicleCardStore,
  State as VehicleCardState
} from '@/store/modules/vehicles/vehicleCard'

export type RootState = {
  authentication: AuthenticationState;
  articles: ArticleState,
  vehicles: VehicleState,
  card: CardArticleState,
  sheet: DevelopmentSheetState,
  messageModal: ModalState,
  device: DeviceState,
  certificates: CertificatesState,
  systemProductGroups: SystemProductGroupsState,
  vehicleCard: VehicleCardState,
};

export type Store =
  AuthenticationStore<Pick<RootState, 'authentication'>>
  & ArticleStore<Pick<RootState, 'articles'>>
  & VehicleStore<Pick<RootState, 'vehicles'>>
  & CardArticleStore<Pick<RootState, 'card'>>
  & DevelopmentSheetStore<Pick<RootState, 'sheet'>>
  & ModalStore<Pick<RootState, 'messageModal'>>
  & DeviceStore<Pick<RootState, 'device'>>
  & CertificatesStore<Pick<RootState, 'certificates'>>
  & SystemProductGroupsStore<Pick<RootState, 'systemProductGroups'>>
  & VehicleCardStore<Pick<RootState, 'vehicleCard'>>

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

export const store = createStore({
  plugins,
  modules: {
    authentication,
    articles,
    vehicles,
    card,
    sheet,
    messageModal,
    device,
    certificates,
    vehicleCard,
    systemProductGroups,
  }
});

export function useStore(): Store {
  return store as Store;
}
