import {httpClient} from "@/httpClient";
import axios from 'axios';

function setFocusCrosses(crossIds: string[]) {
  return httpClient.put(`/article/${localStorage.getItem('cid')}/cross/focus`, crossIds).then(response => response.data)
}

function setFocusCross(id: string) {
  return httpClient.put(`/article/${localStorage.getItem('cid')}/cross/${id}/focus`).then(response => response.data)
}

// Карточка артикула (Добавление кросса)
function addCross(articleId: string, payload: object) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/cross/${articleId}`, payload).then(response => response.data)
}

// Карточка артикула (Принудительное добавление кросса(Только ADMIN))
function addDuplicateCrossToDB(articleId: string, payload: object) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/cross/duplicate/${articleId}`, payload).then(response => response.data)
}

// Карточка артикула (Пдтверждение кросса)
function approveCross(articleId: string, payload: object) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/cross/approve/${articleId}`, payload).then(response => response.data)
}

// Карточка артикула (Принудительное подтверждение кросса(Только ADMIN))
function approveDuplicateCrossToDB(articleId: string, payload: object) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/cross/duplicate/approve/${articleId}`, payload).then(response => response.data)
}

// Карточка артикула (Получение информации из TCDC)
function findCrossInfo(brandArt: string, brandId: string) {
  return httpClient.get(`/api/tecdoc?brandArt=${brandArt}&brandId=${brandId}`).then(response => response.data)
}

function getAllCrosses(articleId: string) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/cross/${articleId}`).then(response => response.data)
}

function getHardCrosses(key: string, item: string, option: string) {
  return httpClient.get(`article/${localStorage.getItem('cid')}/cross/${item}${option ? `/${option}` : ''}/hard`).then(response => response.data)
}

// Удаление кросса
function deleteCross(id: string) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/cross/${id}`).then(response => response.data)
}

function uploadCrossesFromFile(file: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/cross/uploading`, file, {
    headers: {'Content-Type': 'multipart/form-data'},
    responseType: 'blob'
  }).then(response => response.data)
}

function getTemplate() {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/cross/uploading-template`, {
    responseType: 'blob'
  }).then((response) => response.data)
}

function generateFileToTecDoc(payload: any) {
  return httpClient.post(`/api/tecdoc/${localStorage.getItem('cid')}/tecdocFile`, payload)
}

function generateFileToTecDocApplicab(payload: any) {
  return httpClient.post(`/api/tecdoc/${localStorage.getItem('cid')}/tecdocFileApplicab`, payload)
}

function deleteCrosses(crossesIds: any) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/cross?ids=${crossesIds}`)
}

export default {
  setFocusCross,
  addCross,
  addDuplicateCrossToDB,
  approveCross,
  approveDuplicateCrossToDB,
  findCrossInfo,
  getAllCrosses,
  getHardCrosses,
  deleteCross,
  uploadCrossesFromFile,
  getTemplate,
  generateFileToTecDoc,
  generateFileToTecDocApplicab,
  deleteCrosses,
  setFocusCrosses,
}
