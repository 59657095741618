import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';
import vehicles from '@/services/vehicles';
import { AugmentedActionContext } from './index';

export interface IImageData {
  id: string | number
  imageName: string
  vehicleId: string
  files: FileList
}

export interface Actions {
  addImage({ commit }: AugmentedActionContext, payload: IImageData): void
  deleteImage({ commit }: AugmentedActionContext, payload: IImageData): void
  fetchImages({ commit }: AugmentedActionContext, id: string | number): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async fetchImages({ commit, state }, id) {
    try {
      const images = await vehicles.getVehicleImages(id)
      commit('setImages', images)
    } catch (error) {
      console.error(error)
    }
  },
  async addImage({ commit, state }, { vehicleId, files }: IImageData) {
    const formData = new FormData();

    for (let index = 0; index < files.length; index++) {
      const extension = files[index].type.split('/').pop() as string
      formData.append('vehicleId', vehicleId);
      formData.append('extension', extension);
      formData.append('file', files[index]);
    }

    try {
      const img = await vehicles.addVehicleImage(formData)
      commit('setImage', img)
    } catch (error) {
      console.error(error)
    }
  },
  async deleteImage({ commit, state }, { id, imageName, vehicleId }: IImageData) {
    try {
      const name = imageName.substring(imageName.lastIndexOf('/') + 1)
      await vehicles.deleteVehicleImage(id, name, vehicleId)

      commit('deleteImage', imageName)
    } catch (error) {
      console.error(error)
    }
  },
};
