export type State = {
  article: object // Артикулы
  isLoading: boolean
  pageable: { page: number, size: number } // Текущая страница и количество отображения артикулов
  activeTab: any // Текущая активная вкладка SideBar
  sideBar: Array<any> // Боковое меню
  onlyFocusedBrands: boolean
}

export const state: State = {
  article: {},
  isLoading: false,
  onlyFocusedBrands: false,
  pageable: {page: 0, size: 20},
  activeTab: null,
  sideBar: [
    {key: 'BASIC', name: 'Основная информация'},
    {key: 'ARTICLES', name: 'Артикулы'}
  ]
};
