const tooltipDirective = () => {
  return {
    created(event: any, binding: any) {
      const {content, conditionalAddition = true} = binding.value
      if (!conditionalAddition) return

      const randomTooltipId = Math.random().toString(36).substring(2)

      event.style.position = 'relative'
      event.insertAdjacentHTML('afterbegin', `
        <div
          class="tooltip"
          id="${randomTooltipId}"
        >
          ${content}
        </div>
      `);
    },
    mounted(event: any, binding: any) {
      const {
        trigger = 'hover',
        show = false,
        side = 'top-center',
        conditionalAddition = true
      } = binding.value

      if (!conditionalAddition) return

      const tooltipNode = event.getElementsByClassName('tooltip')[0]!

      if (trigger === 'click') {
        const clickFn = event.addEventListener('click', () => {
          if (tooltipNode.classList.contains('tooltip_show')) {
            tooltipNode.classList.remove('tooltip_show', `tooltip_${side}`)
            document.removeEventListener('click', clickFn)
            return;
          }

          tooltipNode.classList.add('tooltip_show', `tooltip_${side}`)
        })
      }

      if (trigger === 'hover') {
        const mouseenterFn = event.addEventListener('mouseenter', () => {
          tooltipNode.classList.add('tooltip_show', `tooltip_${side}`)
        })

        const mouseleaveFn = event.addEventListener('mouseleave', () => {
          tooltipNode.classList.remove('tooltip_show', `tooltip_${side}`)

          document.removeEventListener('mouseenter', mouseenterFn)
          document.removeEventListener('mouseleave', mouseleaveFn);
        })
      }
    }
  }
}

export default tooltipDirective()
