import {GetterTree} from 'vuex';
import {RootState} from '@/store';
import {State} from './state';
import {
  Certificate,
  CertificateProductGroup,
  ErrorFile, LayoutRequests,
  Request
} from '@/models/CertificateModel';

export type Getters = {
  getCertificates(state: State): Array<Certificate>,
  loadingCertificates(state: State): boolean,
  getRequests(state: State): Array<Request>,
  getUploadFileErrors(state: State): Array<ErrorFile>,
  getCertificateProductGroups(state: State): Array<CertificateProductGroup>
  getLayoutsRequests(state: State): Array<LayoutRequests>
}

export const getters: GetterTree<State, RootState> & Getters = {
  getCertificates: (state) => state.certificates,
  loadingCertificates: (state) => state.loadingCertificates,
  getRequests(state: State): Array<Request> {
    return state.requests
  },
  getUploadFileErrors(state: State): Array<ErrorFile> {
    return state.uploadFileErrors
  },
  getCertificateProductGroups(state: State): Array<CertificateProductGroup> {
    return state.certificateProductGroups
  },
  getLayoutsRequests(state: State): Array<LayoutRequests> {
    return state.layoutsRequests
  }
};
