import {MutationTree} from 'vuex';
import {State} from './state';
import { SystemProductGroups } from '@/models/SystemModel';

export type Mutations<S = State> = {
  setSystemsProductGroups(state: S, systemsProductGroups: Array<SystemProductGroups>): void;
  addSystemProductGroups(state: S, system: { name: string, id: number }): void;
  replaceSystemProductGroups(state: S, system: { name: string, id: number }): void;
  setErrorAttachSystem(state: S, error: string): void;
  deleteSystem(state: S, systemId: number): void;
}

export const mutations: MutationTree<State> & Mutations = {
  setSystemsProductGroups(state: State, systemsProductGroups: any) {
    state.systemsProductGroups = systemsProductGroups
  },
  addSystemProductGroups(state: State, system: { name: string, id: number }) {
    state.systemsProductGroups.push({
      pgs: [],
      system
    })
  },
  replaceSystemProductGroups(state: State, system: { name: string, id: number}) {
    const index = state.systemsProductGroups.findIndex((systemProductGroups: SystemProductGroups) => {
      return systemProductGroups.system.id === system.id
    })

    state.systemsProductGroups[index].system = { ...system }
  },
  setErrorAttachSystem(state: State, error: string) {
    state.attachSystemError = error

    setTimeout(() => {
      state.attachSystemError = null
    }, 3000)
  },
  deleteSystem(state: State, systemId: number) {
    state.systemsProductGroups = state.systemsProductGroups.filter((systemProductGroup: any) => {
      return systemProductGroup.system.id !== systemId
    })
  }
};
